section.full-image-block {
    background-color: var(--color-tertiary-background);    
    .single-image-with-caption {
        padding-top: var(--space-mobile-2);
        padding-bottom: var(--space-mobile-2);

        @media screen and (min-width: $lg) {
            padding-bottom: var(--space-desktop-5);
            padding-top: 0;
        }
    }
}