.our-services-card {
  // word-break: break-word;

  .h2 {
    color: var(--color-red);
    padding-top: var(--space-mobile-1);
    padding-bottom: 1.5rem;

    @media screen and (min-width: $lg) {
      padding-top: var(--space-desktop-2);
      max-width: 95%;
    }
  }

  .our-services-card-description {
    padding-bottom: var(--space-mobile-1);

    @media screen and (min-width: $md) {
      max-width: 90%;
    }

    @media screen and (min-width: $lg) {
      max-width: 80%;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;

    @media screen and (min-width: $md) {
      max-width: 90%;
    }

    @media screen and (min-width: $lg) {
      max-width: 80%;
    }
  }
}