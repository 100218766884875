section.packaging-pannels {
    // background-color: var(--color-white);
    // overflow: hidden;

    .wrapper {
        padding-top: var(--space-mobile-2);
        padding-bottom: 0;
        // padding-bottom: var(--space-mobile-5);
        position: relative;

        @media (max-width: $md) {
            padding-bottom: 0;
        }

        #dots-nav {
            right: 40px;
            top: calc(100vh / 2);
            // transform: translateY(-50%);
            z-index: 5;

            @media (max-width: $md) {
                display: none;
            }
        }

    }

    .panel {
        // padding: 0;
        position: relative;

        @include container-grid(start);

        padding-left: calc(var(--space-unit-1));
        padding-right: calc(var(--space-unit-1));
        padding-top: 490px;
        // padding-bottom: 200px;

        border-top-left-radius: 40px;
        border-top-right-radius: 40px;

        overflow: hidden;

        @media (max-width: $md) {
            padding-top: 320px;
        }


        .container {
            display: block;

            >:last-child {
                padding-bottom: 250px;


                @media (max-width: $md) {
                    padding-bottom: 150px;
                }
            }
        }

        .panel-number {
            position: absolute;
            top: 85px;
            left: 135px;
            border-radius: 50%;
            border: 2px solid;
            font-family: var(--font-six-caps);
            font-size: 57px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 135px;
            height: 135px;

            @media (max-width: $md) {
                margin: 0 auto;
                left: 0;
                right: 0;
                width: 100px;
                height: 100px;
                font-size: 38px;
                top: 60px;
            }
        }

        .package-img-container {
            position: absolute;
            top: 300px;
            width: 1000px;
            height: 500px;
            left: 0;
            right: 0;
            margin: 0 auto;

            // remove 
            // border: 1px solid black;

            @media (max-width: $md) {
                top: 220px;
                width: 100vw;
                height: 300px;
            }

            img {
                left: 0;
                right: 0;
                margin: 0 auto;
                position: absolute;
                opacity: .8;

                &.hidden-js {
                    opacity: 0;
                }
            }
        }

        .vertical-header {
            position: absolute;
            top: 400px;
            left: 40px;

            p {
                writing-mode: tb-rl;
                transform: rotate(-180deg);
            }

            @media (max-width: 1500px) {
                display: none;
            }
        }

        .panel-header {
            text-transform: uppercase;

            @media (max-width: $md) {
                text-align: center;
            }
        }

        .panel-content {
            margin-top: 700px;
            position: relative;
            max-width: 330px;
            z-index: 1;

            @media (max-width: $md) {
                margin-top: 200px;
            }

            h5 {
                padding-bottom: 75px;
                margin: 0;
            }

            .case-studies {
                display: flex;
                padding-top: 100px;
                gap: 20px;

                @media (max-width: $md) {
                    padding-top: 50px;
                }

                >div {
                    width: 95px;
                    height: 95px;
                    position: relative;
                    overflow: hidden;

                    &:hover {
                        .arrow {
                            top: 0;
                        }
                    }
                }

                img {
                    border-radius: 20px;
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                }

                button {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    cursor: pointer;
                }

                .arrow {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    pointer-events: none;
                    background-color: var(--color-black);
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    inset: 0;
                    top: 125%;
                    margin: auto;
                    transition: top 0.3s ease-out;

                    @media (max-width: $sm) {
                        display: none;
                    }

                    svg {
                        width: 8px;
                        height: 13px;
                    }
                }
            }
        }

        .quote {
            margin: 0 auto;
            margin-top: 400px;
            width: 100%;
            max-width: 1140px;
            text-align: center;
            position: relative;
            z-index: 11;

            @media (max-width: $md) {
                margin-top: 120px;
            }

            >p {
                font-size: 40px;
                font-family: var(--font-syne);
                font-weight: 400;

                @media (max-width: $md) {
                    font-size: var(--subtitle-mobile-font-size);
                }

                strong {
                    font-weight: inherit;
                    background-color: var(--color-red);
                }
            }

            .author {
                margin-top: 100px;
                text-align: center;

                @media (max-width: $md) {
                    margin-top: 70px;
                }

                img {
                    margin-bottom: 20px;
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    display: inline-block;
                }

                p {
                    margin: 0;
                }

                .name {
                    color: var(--color-red);
                    font-style: normal;
                    font-size: 24px;
                }

                .position {
                    color: var(--color-gray);
                    font-size: 1rem;
                    margin-top: 10px;
                    text-decoration: underline;
                }
            }
        }
    }

}