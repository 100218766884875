html {
  box-sizing: border-box;
  //display: grid;
  //height: 100vh;
  //height: 100dvh;
  --root-background: var(--color-black);
  background-color: var(--root-background);
  font-size: 16px;
  line-height: var(--body-line-height);
}

body {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}