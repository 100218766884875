.faqs {
  background: var(--color-red);
  position: relative;

  @media (min-width: $md) {
    padding-top: var(--space-desktop-3);
    padding-bottom: var(--space-desktop-3);
  }

  #r {
    position: absolute;
    display: none;
    clip-path: path("m487.24,611.65h-108.35c-22.04,0-33.06-8.27-45.91-27.55l-99.17-147.84c-8.27-11.02-10.1-21.12-10.1-46.83v-73.46c0-16.53,5.51-26.63,26.63-26.63h18.37c44.99,0,87.23-31.22,87.23-74.38,0-47.75-36.73-78.05-84.48-78.05h-122.13v448.11c0,21.12-10.1,26.63-26.63,26.63H31.79c-16.53,0-25.71-5.51-25.71-26.63V34.98c0-16.53,4.59-26.63,25.71-26.63h231.4c100.09,0,144.17,20.2,182.73,58.77,33.98,33.06,53.26,85.4,53.26,144.17,0,96.42-70.71,163.45-127.64,182.73l129.47,188.24c8.26,12.86,3.67,29.38-13.78,29.38h0Z");

    @media (min-width: $md) {
      display: block;
      top: 50px;
      bottom: 0;
      width: 600px;
      height: 620px;
      background-attachment: fixed;
    }

    @media (min-width: 1340px){
      right: 60%;
    }

    @media (max-width: 1340px) and (min-width: $lg){
      right: 55%;
    }
    @media (max-width: $lg){
      right: 40%;
    }
  }


  @media (min-width: $lg) {
    background-size: 40%;
  }

  @media (min-width: $xl) {
    background-size: unset;
  }

  .faqs-container {
    color: var(--color-white);
  }

  .container {
    @include container-grid(start);

    .asset-container {
      display: none;

      @media (min-width: $md) {
        display: block;
        grid-column: 1/span 4;
      }
    }

    .faqs-container {
      grid-column: 1/span 4;

      @media (min-width: $md) {
        grid-column: 6/span 8;
      }

      @media (min-width: $lg) {
        grid-column: 7/span 8;
      }

      @media (min-width: $xl) {
        grid-column: 6/span 8;
      }
    }
  }
}