section.oEmbed{
    background-color: var(--color-black);

    &.dynamic{
        background-color: var(--color-white);

        .container{
            padding-top: var(--space-mobile-1);
            padding-bottom: var(--space-mobile-1);

            @media (min-width: $sm) {
                padding-bottom: var(--space-mobile-4);
            }
        }
    }

    .container{
        padding-top: var(--space-mobile-1);
        padding-bottom: var(--space-mobile-1);
        @include container-grid(start);

        &.full{
            max-width: unset;
            padding-left: 0;
            padding-right: 0;

            .embed-container{
                grid-column: 1/span 12;
            }
        }
    
        @media (min-width: $lg) {
        //   padding-top: var(--space-desktop-7);
            padding-top: var(--space-mobile-4);
            padding-bottom: var(--space-desktop-7);
        }
    }

    .embed-container{
        --video--width: 640;
        --video--height: 360;
        
        grid-column: 1/span 10;
        position: relative;
        padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
        overflow: hidden;
        max-width: 100%;


        @media (min-width: $lg) {
            grid-column: 2/span 10;
        }
    }

    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.embed-container.service-embed{
    --video--width: 640;
    --video--height: 360;

    margin: 3rem 0;
    
    position: relative;
    padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
    overflow: hidden;
    max-width: 100%;

    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}