section.packaging-pannels .panel{
    &.one {
        background-color: var(--color-red);
        color: var(--color-white);

        &::after {
            position: absolute;
            content: '';
            bottom: -150px;
            left: 0;
            width: 100%;
            height: 150px;
            background-color: var(--color-red);
        }

        .package-img-container {
            img {
                width: 450px;

                @media (max-width: $md){
                    width: 350px;
                }

                @media (max-width: $md){
                    width: 200px;
                }
            }
        }

        .canvas-container{
            position: absolute;
            top: 150px;
            width: 500px;
            height: 500px;
            left: 30px;
            right: 0;
            margin: 0 auto;

            // border: 1px solid black;

            @media (min-width: $md){
                top: 200px;
                left: 0;
                width: 800px;
                height: 8000px;
            }

            @media (min-width: $lg){
                width: 1000px;
                height: 1000px;
            }

            canvas{
                width: inherit;
                height: inherit;
                transform: rotate(-45deg);
                opacity: 0.7;
                // border: 1px solid orange;
            }
        }
    }

}