.featured-entries {
  background-color: var(--section-background);
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  .container:not(.title-container, .offset-left-title) {
    @include container-grid(center);
    padding-bottom: var(--space-mobile-2);
    // position: relative;
    // z-index: 1;

    @media (min-width: $lg) {
      padding-bottom: var(--space-desktop-4);
    }

    .grid-item:not(.service-card) {
      @include grid-item-full-mobile;

      @media (min-width: 550px) {
        grid-column: span 2;
      }

      @media (min-width: $md) {
        grid-column: span 4;
      }

      @media (min-width: $lg) {
        grid-column: span 6;
      }
    }
  }

  .title-container {
    @include container-grid(center);
    color: var(--color-white);
  }

  .line {
    height: 1px;
    background-color: var(--color-white);
    width: 100%;
    grid-column: 1/-1;
  }

  &.left-title {
    .container {
      align-items: start;

      .title-grid-item {
        --color: var(--color-white);
        color: var(--color);
        @include set-full-grid-item;
        @include set-grid-item-columns($lg, 1, 1);
        margin-bottom: 60px;

        @media (min-width: $lg) {
          margin-bottom: unset;
        }

        span {
          @media (min-width: $lg) {
            display: block;
            transform: rotate(180deg);
            writing-mode: vertical-lr;
            transform-origin: center;
          }
        }
      }

      .grid-item:not(.service-card) {
        &.card {
          @include grid-item-full-mobile;


          &:first-of-type {
            @include set-grid-item-columns($md, 1, 4);
            @include set-grid-item-columns($lg, 2, 5);
            grid-row: 2;
          }

          &:last-of-type {
            @include set-grid-item-columns($md, 5, 4);
            @include set-grid-item-columns($lg, 7, 5);
            grid-row: 3;
          }

          @media (min-width: $md) {
            grid-row: 2 !important;
          }

          @media (min-width: $lg) {
            grid-row: 1 !important;
          }
        }
      }
    }
  }

  .fake-two {
    background-color: var(--fake-background);
    height: 75%;
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    //inset: 0;

    // @media (min-width: 550px) {
    //   height: 50%;
    // }
  }
}