section.double-images-block {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    background: var(--color-secondary);

    .container {
        padding-top: 0 ;
        @include container-grid(center);
        z-index: 1;

        @media (min-width: $lg) {
            max-height: var(--desktop-max-height);
        }

        .grid-item {
            @include grid-item-full-mobile;
            position: relative;

            @media (min-width: 550px) {
                grid-column: span 2;
            }

            @media (min-width: $md) {
                grid-column: span 4;
            }

            @media (min-width: $lg) {
                grid-column: span 6;
            }
        }
    }
}