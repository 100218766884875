.service-banner {
  @include ratio-container(100%, unset);

  @media (min-width: $lg) {
    max-height: var(--desktop-max-height);
  }

  figure {
    display: contents;
  }

  .image-banner {
    position: absolute;
    inset: 0;

    @media (min-width: $lg) {
      position: relative;
      max-height: var(--desktop-max-height);
    }
  }

  .ratio-content {
    z-index: 2;
    align-items: end;
    height: 100%;
    --padding: 0 calc(var(--space-unit-1));

    .banner-caption {
      border-radius: 10px;
      background-color: var(--color-white);
      padding: 10px;
      margin-bottom: 35px;
    }
  }
}