body.blog-entries-template {
  footer.main-footer {
    --background: var(--color-black);

    .container {
      &.bottom-links {
        .social-accounts {
          .social-accounts-list {
            li {
              a svg path {
                --fill: var(--color-white);
              }

              &:hover {
                a svg path {
                  --fill: var(--color-red);
                }
              }
            }
          }
        }

        .item {
          a {
            --color: var(--color-white);
          }

        }
      }

      a {
        --color: var(--color-white);
      }
    }
  }
}