section.paragraph-block {
    background-color: var(--color-white);
    padding-top: var(--space-mobile-2);
    padding-bottom: var(--space-mobile-2);
    
    @media screen and (min-width: $lg){
        padding-bottom: var(--space-desktop-4);
        padding-top: var(--space-desktop-4);
    }

    &.blog-entries-item {
        padding-bottom: var(--space-mobile-2);
        @media (min-width: $lg) {
            padding-top: var(--space-desktop-6); 
            padding-bottom: var(--space-desktop-7);
        }
    }
    &.full-width {
        .container {
            .grid-columns {
                @media screen and (min-width: $lg){
                    @include container-columns(12, 6, 2, 4, 9, true);
                }
                @media screen and (min-width: $xl){
                    @include container-columns(12, 11, 2, 0, 0, true);
                }
            }
        }
    }
    .container {
        border-left: 4px solid var(--color-red);
        @media screen and (min-width: $lg){
            padding: 0;
            border-left: 2px solid var(--color-red);
            
        }
        .grid-columns {
            @media screen and (min-width: $lg){
                @include container-columns(12, 6, 2, 4, 9, true);
            }
            @media screen and (min-width: $xl){
                @include container-columns(12, 6, 2, 4, 9, true);
            }
        }
    }
}
