.contact-form {
  --background: var(--color-black);
  background-color: var(--background);

  .container {
    @include container-grid(start);
    padding-top: 0;
    padding-bottom: var(--space-desktop-6);

    @media (min-width: $lg) {
      padding-bottom: calc(var(--space-desktop-8) + 75px);
    }

    .contact-form-container {
      @include grid-item-full-mobile;
      @include set-grid-item-columns($md, 2, 6);
      @include set-grid-item-columns($lg, 3, 8);
      color: var(--color-white);

      .grid-form {
        display: grid;
        --columns: 1fr;
        --gap: var(--space-mobile-1) var(--gutter-mobile);
        grid-template-columns: var(--columns);
        gap: var(--gap);

        @media (min-width: $sm) {
          --columns: 1fr 1fr;
        }

        @media (min-width: $md) {
          --gap: var(--space-desktop-2) var(--gutter-desktop);
        }
      }

      form {
        .field-group {
          overflow: hidden;

          &>*+* {
            margin-top: 10px;
          }

          label {
            color: var(--color-gray);
          }
        }

        .field {
          display: flex;
          align-items: center;
          position: relative;
          border-bottom: 2px solid var(--color-red);

          &:focus-within {
            &::after {
              transform: translateX(0);
            }
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--color-white);
            transform: translateX(-100%);
            transition: transform .4s ease;
          }

          select {
            position: relative;
            z-index: 1;
          }

          select+svg {
            position: absolute;
            right: 10px;
            margin-bottom: 5px;
            display: block;
          }
        }

        .required-mark {
          color: var(--color-red);
        }

        .submit-button {
          color: var(--color-black);

          [disabled] {
            pointer-events: none;
          }
        }

        // .submit-group {
        //   grid-column: 1 / -1;
        // }
      }
    }
  }
}