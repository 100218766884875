section.text-with-services-table {
    background-color: var(--color-white);
    padding-top: var(--space-desktop-2);
    padding-bottom: var(--space-mobile-2);

    @media (min-width: $lg) {
        padding-top: var(--space-desktop-6);
        padding-bottom: var(--space-desktop-5);
    }

    .container {
        padding: 0;
        @media screen and (min-width: $lg){
            border-left: 2px solid var(--color-red);
        }
        .about-hero-title {
            padding-bottom: var(--space-desktop-3)
        }
        .text-with-services-table-content {
            
            .grid-columns {
                @media screen and (min-width: $lg){
                    @include container-columns(12, 6, 2, 4, 9);
                }
                @media screen and (min-width: $xl){
                    @include container-columns(12, 6, 2, 4, 9);
                }
                .redactor-paragraph {
                    border-left: 4px solid var(--color-red);
                    padding: calc(var(--space-unit-1));
                    @media screen and (min-width: $lg){
                        padding: 0;
                        border: none;
                    }
                }
                .text-with-services-table-wrapper {
                    background-color: var(--color-pink);
                    padding: var(--space-mobile-1);
                    margin-top: var( --space-mobile-2);

                    @media screen and (min-width: $lg){
                        padding: var(--space-desktop-1);
                        margin-top: 0;
                        border-radius: 2rem 0 0 2rem;
                    }

                    @media screen and (min-width: $xxl){
                        border-radius: 2rem;
                    }

                    .text-button-text {
                        color: var(--color-red);
                    }

                    .service-client-logo {
                        display: flex;
                        justify-content: center;
                        padding: 6rem 0;

                        img {
                            /* max-height: 4rem; */
                            max-width: 250px;
                            /* width: 17rem; */
                            width: auto;
                            height: auto;
                        }
                    }

                    .services-list {
                        ul {
                            padding-left: 0;
                            margin: 0;
                            li {
                                border-bottom: 1px solid var(--color-red);
                                list-style-type: none;
                                padding-top: 2rem;
                                padding-bottom: 0.5rem;
                                &:last-child {
                                    border: none;
                                    padding-bottom: 0;
                                }
                                .icon-check {
                                    padding-right: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}