section.team-members {
  @media (min-width: $lg) {
    padding-bottom: var(--space-desktop-6);
  }

  .container {
    .team-members-heading {
      padding-bottom: var(--space-mobile-2);
      display: flex;
      align-items: center;

      @media screen and (min-width: $lg) {
        padding-bottom: var(--space-desktop-5);
      }

      h2 {
        color: var(--color-white);
        font-family: var(--h4-font-family);
        background-color: var(--color-black);
        display: inline-flex;
        flex-shrink: 0;
        font-size: 14px;
        margin-right: 1rem;

        @media screen and (min-width: $lg) {
          margin-right: 4rem;
        }
      }

      span {
        display: flex;
        height: 2px;
        width: 100%;
        background-color: var(--color-red);
        color: var(--color-red);
      }
    }

    .team-members-cards-wrapper {
      @media screen and (min-width: $md) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
      }

      @media screen and (min-width: $xl) {
        gap: calc(18rem - 40px);
      }

      .team-member-card {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}