.service-description {
  background-color: var(--color-black);
  color: var(--color-white);

  .container {
    @include container-grid(start);
    row-gap: 50px;
    padding-top: var(--space-mobile-4);
    padding-bottom: var(--space-mobile-1);

    @media (min-width: $lg) {
      padding-top: var(--space-desktop-7);
      // padding-bottom: var(--space-desktop-7);
    }

    h2,
    h3 {
      color: var(--color-red);
      margin-bottom: 30px;
    }

    .grid-item-content {
      @include grid-item-full-mobile;
      @include grid-item-set-tablet(1, 4);
      @include grid-item-set-desktop(2, 6);

      .redactor-content {
        max-width: 550px;
      }
    }

    .grid-item-offerings {
      @include grid-item-full-mobile;
      @include set-grid-item-columns($md, 6, 3);
      @include set-grid-item-columns($lg, 9, 3);

      @media (min-width: $md) {
        padding-top: 70px;
        position: sticky;
        top: 3rem;
      }

      .list {
        li+li {
          margin-top: 20px;
        }
      }
    }
  }
}