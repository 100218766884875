body.contact-template {
  background: var(--color-white);

  header.main-header {
    --background: var(--color-black);
  }

  .hero-intro.contact {
    --background: var(--color-black);

    .container {
      .subheading {
        --color: var(--color-white);
      }
    }
  }

  main {
    section.hero-intro {
      .wrapper {
        padding-bottom: var(--space-mobile-2);

        @media (min-width: $lg) {
          padding-bottom: var(--space-desktop-5);
        }
      }
    }

    section.call-to-action {

      @media (min-width: $md) {
        position: relative;

        .container {
          margin-top: -75px;
        }
      }

      @media (min-width: $lg) {
        .container {
          margin-top: -125px;
        }
      }
    }
  }
}