// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

button,
.button,
[type="button"] {
  all: unset;
  --outline: -webkit-focus-ring-color auto 1px;

  &.focus-visible {
    outline: var(--outline);
  }
}

.button:not(.navigation-button) {
  all: unset;
  display: inline-block;
  background-color: var(--color-white);
  transform: perspective(1px) translateZ(0);
  position: relative;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  padding: 10px 16px;
  font-family: var(--button-text-font-family);
  font-size: var(--button-text-font-size);
  line-height: var(--button-text-line-height);
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--color-red);
    border-radius: 20px;
    //transform: scaleX(0);
    transform: translateX(-100%);
    transform-origin: 0 50%;
    opacity: 0;
    z-index: -1;
    transition: transform .3s ease-out, opacity .3s ease-out;
    // pointer-events: none;
  }

  &:hover,
  &.focus-visible {
    &::after {
      transform: translateX(0);
      opacity: 1;
    }
  }

}

.button.call-to-action,
.button.cookies-button {
  background-color: var(--button-background);
  color: var(--button-color);

  &.focus-visible,
  &:hover {
    color: var(--button-hover-color);

    &::after {
      background-color: var(--button-hover-background);

    }
  }
}

button.navigation-button {
  position: relative;
  display: grid;
  place-items: center;
  width: 35px;
  height: 35px;
  cursor: pointer;


  .icon {
    transform-origin: center;
    position: absolute;
    display: block;
    transition-property: opacity, transform;
    pointer-events: none;
    @include transition-300;

    path {
      stroke: var(--color-white);
    }
  }

  &.focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px;
  }

  &:hover {
    .icon {
      transform: scale(1.2);
    }
  }
}

button.navigation-button[aria-expanded="false"],
button.navigation-button[data-expanded="false"] {
  .icon-close {
    opacity: 0;
    transform: scale(0);
  }
}

button.navigation-button[aria-expanded="true"],
button.navigation-button[data-expanded="true"] {
  .icon-close {
    opacity: 0;
    transform: scale(0);
  }
}

button.close-lightbox {
  right: 25px;
  top: 25px;

  @media (min-width: $md) {
    right: 50px;
    top: 50px;
  }
}

button.close-video {
  right: 50px;
  top: 50px;
}

button.close-video,
button.close-lightbox,
button.close-cookies {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
  pointer-events: none;
  --background: var(--color-red);
  background-color: var(--background);
  border-radius: 50%;
  cursor: pointer;
  --pseudo-background: var(--color-white);

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:after,
  &:before {
    position: absolute;
    inset: 0;
    margin: auto;
    content: '';
    height: 2px;
    width: 70%;
    transform-origin: center;
    background-color: var(--pseudo-background);
    border-radius: 3px;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

.scroll-button {
  display: none;
  height: 95px;
  width: 95px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transform: translateY(12px);
  right: 2%;
  bottom: 10%;

  @media (min-width: $md) {
    display: block;
  }

  &:hover,
  &.focus-visible {
    .scroll-button-overlay {
      --background: var(--color-red);

    }
  }

  .scroll-button-overlay {
    --background: transparent;
    display: inline-block;
    background-color: var(--background);
    position: absolute;
    inset: 0;
    transition: background .3s ease;
    opacity: .2;
  }

  &>svg {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  // &.loading {
  //   transform: translate3d(0, 12px, 0);
  //   opacity: 0;
  // }

  &.to-top {
    opacity: 1;
    transform: translateY(0);
    bottom: 0;
    right: 0;
    top: 0;
  }
}