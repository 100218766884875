section.about-hero {
  // padding-top: var(--space-mobile-2);
  // @media (min-width: $lg) {
  //     padding-top: var(--space-desktop-7);
  // }

  .h1,
  p,
  span {
    color: var(--color-white);
  }

  h1 {
    @media screen and (min-width: $lg) {
      padding-bottom: var(--space-mobile-1);
    }
  }

  .container {
    // @media screen and (min-width: $lg) {
    //     height: 100vh;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    // }


    .about-hero-title {
      margin-top: var(--space-mobile-1);
      padding-bottom: var(--space-desktop-1)
    }

    .about-hero-content {
      .grid-columns {
        @media screen and (min-width: $lg) {
          @include container-columns(12, 4, 1, 7, 6);
        }

        @media screen and (min-width: $xl) {
          @include container-columns(12, 3, 1, 6, 5);
        }

        .about-hero-date-and-image {
          .about-hero-image {
            margin-bottom: var(--space-mobile-2);
            position: relative;
            overflow: hidden;
            border-radius: 0 2rem 2rem 0;
            transform: translateX(calc(var(--space-mobile-1) * -1));
            max-height: 521px;

            @media screen and (min-width: $lg) {
              transform: translateX(-10rem);
              border-radius: 2rem;
              margin-right: -11rem;
              height: 521px;
            }

            img {

              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;

              @media (max-width: $lg) {
                border-radius: 0 2rem 2rem 0;
              }

              &:not(.one) {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(100%);

                @media (max-width: $lg) {
                  display: none;
                }
              }
            }
          }
        }

        .about-hero-date {
          display: flex;
          align-items: flex-end;
          padding-bottom: var(--space-mobile-1);

          @media screen and (min-width: $lg) {
            padding-bottom: var(--space-mobile-2);
          }

          p {
            position: relative;

            span {
              display: inline-block;
              font-size: var(--h4-desktop-font-size);

              &.hidden-js {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transform: translateY(-100%);
              }
            }
          }

          .year-js {
            margin-left: 1rem;
            position: relative;
            text-align: center;

            &::after {
              content: "";
              position: absolute;
              height: 0.8rem;
              width: 100%;
              background-color: var(--color-red);
              color: var(--color-red);
              bottom: -1rem;
              right: 0;
              z-index: 1;
            }
          }
        }

        .about-hero-paragraph {
          padding-bottom: var(--space-mobile-1);
          overflow: hidden;
          position: relative;

          @media screen and (min-width: $lg) {
            margin-top: var(--space-desktop-1);
          }

          .about-hero-date {
            margin-top: 2rem;

            @media screen and (min-width: $lg) {
              display: none;
            }
          }

          >p {
            margin-bottom: 1rem;
          }

          >p:not(.main) {
            @media screen and (min-width: $lg) {
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              transform: translateY(-100%);
            }
          }
        }
      }
    }

    .scroll-label {
      display: none;
      position: absolute;
      right: 0;
      transform: translateY(-200%);
      writing-mode: vertical-lr;
      height: 360px;

      @media screen and (min-width: $xl) {
        display: flex;
        align-items: center;
      }

      span,
      svg {
        display: inline-block;
      }

      .arrow {
        height: 130px;
        display: flex;
        align-items: center;

        .line {
          height: 100%;
          width: 2px;
          background-color: var(--color-red);
          animation: elastic 1.5s cubic-bezier(1, 0, 0, 1) infinite;
        }

        svg {
          margin-top: -8px;
          position: relative;
          z-index: 1;
        }
      }

      &.arrow {
        svg {
          .line {
            //transform: scale(1);
            stroke-dashoffset: 0;
            animation: elastic 3s cubic-bezier(1, 0, 0, 1) infinite;
          }

          // .line,
          // .caret {
          //   transform: scale(1);
          //   animation: elastic 1.2s cubic-bezier(1, 0, 0, 1) infinite;
          // }
        }
      }

      svg {

        .line {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          // transition-property: stroke-dashoffset;
          // transition-timing-function: ease;
          //transform: scale(1, 0);
          //animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        }

        // .line {
        //   transition-duration: 2s;
        // }

        // .caret {
        //   transition-duration: 3s;
        //   animation: bounce 2s ease-in-out infinite 3s;
        // }

        // .caret {
        //   transition-delay: .5s;
        // }
      }

      span {
        margin-bottom: 15px;
      }
    }
  }
}


@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(-20%);
  }

  65% {
    transform: translateY(-25%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes elastic {
  0% {
    transform-origin: 0% 0%;
    transform: scale(1, 0);
  }

  50% {
    transform-origin: 0% 0%;
    transform: scale(1, 1);
  }

  50.1% {
    transform-origin: 0% 100%;
    transform: scale(1, 1);
  }

  100% {
    transform-origin: 0% 100%;
    transform: scale(1, 0);
  }
}