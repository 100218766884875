section.simple-images {
  padding-bottom: var(--space-desktop-2);

  @media screen and (max-width: $md) {
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
      /* make scrollbar transparent */
    }
  }

  @media screen and (min-width: $lg) {
    padding-bottom: var(--space-desktop-7);
  }

  .simple-images-wrapper {
    white-space: nowrap;

    @media screen and (min-width: $xxxl) {
      margin: 0 auto;
    }

    >div {
      display: inline-block;
      width: 210px;
      height: 205px;
      margin-right: 40px;
      position: relative;

      &:first-child {
        margin-left: 40px;
      }

      @media screen and (min-width: $lg) {
        width: 510px;
        height: 505px;

        margin-right: calc($max-width / 18);

        &:first-child {
          margin-left: calc($max-width / 18);
        }
      }

      .button-modal-lightbox {
        position: absolute;
        right: 15px;
        top: 10px;
        z-index: 1;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        z-index: 1;
        cursor: pointer;
        transition: transform .3s ease;
        display: none;

        &:hover {
          transform: scale(1.1);
        }

        &.focus-visible {
          outline: 3px solid var(--color-white);
          outline-offset: 2px;
          transform: scale(1.1);
        }

        svg {
          pointer-events: none;
          position: relative;
          z-index: -1;
        }

        @media (min-width: $md) {
          display: block;
        }
      }
    }

    img {
      border-radius: 2rem;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}