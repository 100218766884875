.cropmarks{
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    // delete
    // border: 1px solid blue;

    z-index: 1;

    $two: 2px;
    $seventy: 55%;

    &.show-topLeft{ div:nth-child(1){display: block;} }
    &.show-topRight{ div:nth-child(2){display: block;} }
    &.show-bottomLeft{ div:nth-child(3){display: block;} }
    &.show-bottomRight{ div:nth-child(4){display: block;} }

    &.inset{
        div.left{ left: 0;}
        div.right{ right: 0;}
    }

    div{
        width: 40px;
        height: 40px;
        position: absolute;
        display: none;

        // delete
        // background-color: orange;

        &:before, &:after{
            position: absolute;
            background-color: var(--color-red);
            content: '';
            z-index: 1;
        }

        &.left{ left: -100px; }

        &.top{ top: 0; transform: translateY(-100%); }

        &.right{ right: -100px; }

        &.bottom{ bottom: 0; transform: translateY(100%); }

        // @mixin cropmark-position($right, $left, $top, $bottom, $margin, $height, $width)

        &.left.top{
            &:before{
                @include cropmark-position(0, unset, 0, 0, 0, $seventy, $two)
            }
            
            &:after{
                @include cropmark-position(0, 0, unset, 0, 1, $two, $seventy)
            }
        }

        &.left.bottom{
            &:before{
                @include cropmark-position(0, 0, 0, unset, 1, $two, $seventy)
            }
            
            &:after{
                @include cropmark-position(0, unset, 0, 0, 0, $seventy, $two)
            }
        }

        &.right.top{
            &:before{
                @include cropmark-position(unset, 0, 0, 0, 0, $seventy, $two)
            }
            
            &:after{
                @include cropmark-position(0, 0, unset, 0, 1, $two, $seventy)
            }
        }

        &.right.bottom{
            &:before{
                @include cropmark-position(0, 0, 0, unset, 1, $two, $seventy)
            }
            
            &:after{
                @include cropmark-position(unset, 0, 0, 0, 0, $seventy, $two)
            }
        }
    }
}