.service-intro {
  background-color: var(--color-white);
  overflow: hidden;

  &.packaging-intro {
    .wrapper {
      padding-bottom: var(--space-mobile-1);
      padding-top: var(--space-mobile-2);
    }

  }

  .wrapper {
    padding-top: var(--space-mobile-2);
    padding-bottom: var(--space-mobile-5);
    padding-left: calc(var(--space-unit-1));
    padding-right: calc(var(--space-unit-1));

    @media (min-width: $lg) {
      padding-top: var(--space-desktop-4);
    }
  }

  .container {
    padding: 0;
    position: relative;
    @include container-grid(start);

    h1 {
      --color: var(--color-red);
      color: var(--color);
      text-align: center;
      margin: 25px auto 15px;
    }

    .grid-item-text {
      .content {
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    // use in packaging intro
    .scroll-to-container {
      text-align: center;

      .scroll-to {
        width: 120px;
        height: 120px;
        margin-top: 25px;
        background-color: var(--color-black);
        border-radius: 50%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--color-white);
        cursor: pointer;
        transition: transform 0.4s ease;

        &:hover{
          transform: scale(1.05);
        }

        @media (min-width: $md){
          width: 140px;
          height: 140px;
        }

        svg {
          margin: 10px 0;
        }

        span {
          font-size: 14px;
          font-family: var(--font-syne);

          @media (min-width: $md){
            font-size: 15px;
          }
        }
      }
    }
  }

}