div.video-mobile-controls{
    position: absolute;
    width: 210px;
    height: 50px;
    // bottom: 12px;
    margin: 0 auto;

    opacity: 0;

    // border: 1px solod red;

    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity 0.3s linear;

    &.active{
        opacity: 1;
    }


    button{
        width: 30px;
        height: 30px;
        color: black;

        cursor: pointer;

        svg{
            height: 100%;
            width: 100%;
        }
    }
}