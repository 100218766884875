section.packaging-pannels .panel{

    margin-top: -50px;

    &.three {
        z-index: 3;
        background-color: var(--color-white);
        color: var(--color-black);

        .package-img-container {
            top: 450px;
            width: 850px;

            @media (max-width: $md){
                width: 430px;
                top: 250px;
            }

            @media (max-width: $sm){
                width: 350px;
                top: 300px;
            }

            img {
                &.one{
                    width: 450px;
                    left: 0;
                    right: unset;
                    z-index: 2;
                }

                &.two{
                    width: 350px;
                    top: 100px;
                    right: 0;
                    left: unset;
                }

                @media (max-width: $md){
                    &.one{
                        width: 250px;
                    }
    
                    &.two{
                        width: 150px;
                        top: 50px;
                    }
                }

                @media (max-width: $sm){
                    &.one{
                        width: 200px;
                    }
    
                    &.two{
                        width: 100px;
                        top: 50px;
                    }
                }
            }
        }
    }

}