section.packaging-pannels .panel{

    &.five {
        z-index: 4;
        background-color: var(--color-black);
        color: var(--color-white);

        padding-bottom: 200px;

        @media (max-width: $md){
            padding-bottom: 0px;
        }



        .package-img-container {
            width: 580px;
            top: 400px;

            @media (max-width: $md){
                top: 250px;
                width: 280px;
            }

            img {
                &.one{
                    width: 280px;
                    left: 0;
                    top: -100px;
                    right: unset;
                    z-index: 2;
                }

                &.two{
                    width: 250px;
                    right: 0;
                    left: unset;
                }

                @media (max-width: $md){
                    &.one{
                        width: 120px;
                        top: 0px;
                    }
    
                    &.two{
                        width: 100px;
                    }
                }
            }

        }
    }
}