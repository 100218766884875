.index-grid {
  --section-background: var(--color-black);
  background-color: var(--section-background);
  position: relative;

  &::after {
    --height: 40vh;
    height: var(--height);
    content: '';
    position: absolute;
    top: 0;
    // height: calc(90% - var(--height));
    width: 100%;
    background: var(--color-white);

    @media (min-width: 550px) {
      --height: 380px;
    }

    @media (min-width: $lg) {
      --height: 450px;
    }

    @media (min-width: $xl) {
      --height: 500px;
    }
  }

  @media (min-width: $lg) {
    padding-top: var(--space-desktop-2);
  }

  .wrapper {
    padding: var(--space-mobile-2) var(--space-unit-1) 0;

    @media (min-width: $lg) {
      padding: 50px;
      display: block;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .container:is(.empty-container) {
    padding: 0
  }

  .container:is(.grid-index-container) {
    @include container-grid(start);
    --gap: var(--space-mobile-2) 20px !important;
    padding-top: 0;

    @media (min-width: $md) {
      padding-bottom: var(--space-desktop-6);
    }

    @media (min-width: $lg) {
      --gap: var(--space-desktop-6) 20px !important;
    }

    @media (min-width: $lg) {
      padding-bottom: calc(var(--space-desktop-7) + var(--space-desktop-2));
    }

    .title-grid {
      //padding: var(--space-mobile-1) 0;

      @media (min-width: $lg) {
        padding-top: 50%;
        grid-column: 1/span 1 !important;
        //justify-self: end;

        grid-row: 1;
        align-self: start;
      }

      &>div {
        text-align: center;

        @media (min-width: $lg) {
          width: 100%;
          transform: rotate(180deg);
          transform-origin: center;
          writing-mode: vertical-lr;
        }

      }
    }

    .service-card {
      @include grid-item-full-mobile;

      @media (min-width: 550px) {
        grid-column: span 2;
      }

      @media (min-width: $md) {
        grid-column: span 4;
      }

      @media (min-width: $lg) {
        &.card-odd {
          grid-column: 2 / span 5;
        }

        &.card-even {
          grid-column: 7 / span 5;
        }
      }
    }
  }
}