/**
 * Used to create a two columns dynamic grid system.
 *
 * num-columns: Number of columns we want to split the container in.
 * size-left: Number of columns the first div is gonna take.
 * offset-left: The column number the first div is going to start.
 * size-right: Number of columns the second div is gonna take.
 * offset-right: The column number the second div is going to start.
 * single-column: if the grid only has one column.
 */
/* A mixin that is used to create a section with a side. */
/* This mixin is used to set the grid-column property of an element on mobile. */
/* This mixin is used to set the grid-column property of an element on tablet.
start: The column number the element is going to start.
span: The number of columns the element is going to span. */
/* This mixin is used to set the grid-column property of an element on desktop.
start: The column number the element is going to start.
span: The number of columns the element is going to span. */
/* This mixin is used to set the grid-column property of an element.
bp: The breakpoint at which the grid-column property is set.
start: The column number the element is going to start.
span: The number of columns the element is going to span.
*/
/* This mixin is used to set the grid-column property of an element.
bp: The breakpoint at which the grid-column property is set.
*/
/* A mixin that is used to create a grid system. */
/* A mixin that is used to create a hover effect on a link. */
/* Mixin for use inside redactor-content > a */
/* Creating a mixin that is used to create a black hover effect on a link. */
/* A mixin that is used to create a ratio container.
paddingMobile: The padding-top value for mobile.
paddingDesktop: The padding-top value for desktop.
destroyDesktop: If true, the padding-top value for desktop will be unset.
*/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

nav > ul[role=list],
nav > ol[role=list],
.list {
  padding: 0;
  margin: 0;
}
nav > ul[role=list] li > a,
nav > ol[role=list] li > a,
.list li > a {
  text-decoration: none;
  color: inherit;
}

/* Set core root defaults */
/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

main:focus {
  outline: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

body {
  overflow-x: hidden;
}

address {
  font-style: normal;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html {
  box-sizing: border-box;
  --root-background: var(--color-black);
  background-color: var(--root-background);
  font-size: 16px;
  line-height: var(--body-line-height);
}

body {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

/**
 * Basic typography style for copy text
 */
html:not(.no-js) .h1,
html:not(.no-js) .button,
html:not(.no-js) .subtitle,
html:not(.no-js) .social-links *,
html:not(.no-js) .logo-heading {
  opacity: 0;
  transition: opacity 0.3s ease;
}
html:not(.no-js).fonts-loaded.motion-reduce .h1,
html:not(.no-js).fonts-loaded.motion-reduce .button,
html:not(.no-js).fonts-loaded.motion-reduce .subtitle,
html:not(.no-js).fonts-loaded.motion-reduce .social-links *,
html:not(.no-js).fonts-loaded.motion-reduce .logo-heading {
  opacity: 1 !important;
}

.panel-header {
  font-family: var(--panel-font-family);
  line-height: 1;
  font-weight: normal;
  font-size: var(--panel-mobile-font-size);
}
@media (min-width: 768px) {
  .panel-header {
    font-size: var(--panel-tablet-font-size);
  }
}
@media (min-width: 1024px) {
  .panel-header {
    font-size: var(--panel-desktop-font-size);
  }
}

.h1 {
  --fz: var(--h1-mobile-font-size);
  --lh: var(--h1-mobile-line-height);
  font-family: var(--h1-font-family);
  line-height: var(--lh);
  font-weight: normal;
  color: var(--color-primary-text);
  font-size: clamp(var(--h1-mobile-font-size), var(--h1-coefficient), var(--h1-desktop-font-size));
}
@media (min-width: 1280px) {
  .h1 {
    --lh: var(--h1-desktop-line-height);
  }
}

.h2 {
  --fz: var(--h2-mobile-font-size);
  --lh: var(--h2-mobile-line-height);
  font-family: var(--h2-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--h2-mobile-font-size), var(--h2-coefficient), var(--h2-desktop-font-size));
  letter-spacing: 0.03em;
  font-weight: normal;
}
@media (min-width: 1024px) {
  .h2 {
    --lh: var(--h2-desktop-line-height);
  }
}
.h2.smaller {
  line-height: 1;
  font-size: clamp(25px, var(--h3-coefficient), 40px);
}

.h3 {
  --fz: var(--h3-mobile-font-size);
  --lh: var(--h3-mobile-line-height);
  font-family: var(--h3-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--h3-mobile-font-size), var(--h3-coefficient), var(--h3-desktop-font-size));
  letter-spacing: 2px;
  font-weight: normal;
}
@media (min-width: 1024px) {
  .h3 {
    --lh: var(--h3-desktop-line-height);
  }
}

.h4 {
  --fz: var(--h4-mobile-font-size);
  --lh: var(--h4-mobile-line-height);
  font-family: var(--h4-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--h4-mobile-font-size), var(--h4-coefficient), var(--h4-desktop-font-size));
  font-weight: normal;
}
@media (min-width: 1024px) {
  .h4 {
    --lh: var(--h4-desktop-line-height);
  }
}

.subtitle {
  --fz: var(--subtitle-mobile-font-size);
  --lh: var(--subtitle-mobile-line-height);
  font-family: var(--subtitle-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--subtitle-mobile-font-size), var(--subtitle-coefficient), var(--subtitle-desktop-font-size));
  font-weight: normal;
}
@media (min-width: 1024px) {
  .subtitle {
    --lh: var(--subtitle-desktop-line-height);
  }
}

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a,
.h1 > a,
.h2 > a,
.h3 > a,
.h4 > a,
.h5 > a,
.h6 > a {
  text-decoration: none;
  color: inherit;
}

.quote,
blockquote {
  --fz: var(--quote-mobile-font-size);
  --lh: var(--quote-mobile-line-height);
  font-family: var(--quote-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--quote-mobile-font-size), var(--quote-coefficient), var(--quote-desktop-font-size));
  font-style: italic;
  font-weight: normal;
}
@media (min-width: 1024px) {
  .quote,
  blockquote {
    --lh: var(--quote-desktop-line-height);
  }
}

.button-text,
.text-button-text,
.button {
  font-family: var(--button-text-font-family);
  line-height: var(--button-text-line-height);
  font-size: var(--button-text-font-size);
  font-weight: normal;
}

.link-text,
.text-link-text {
  font-family: var(--link-text-font-family);
  line-height: var(--link-text-line-height);
  font-size: var(--link-text-font-size);
  font-weight: normal;
}

p a {
  color: inherit;
  text-decoration-thickness: 2px;
}

html:not(.motion-reduce) .overlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: var(--color-red);
  will-change: transform;
}

html:is(.motion-reduce) overlay-layer .overlay {
  display: none;
}

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: var(--layout-max-width);
  /* 1 */
  margin-left: auto;
  /* 2 */
  margin-right: auto;
  /* 2 */
  /* 3 */
  padding: calc(var(--space-unit-1));
  width: 100%;
  /* 1 */
  display: var(--container-display, block);
}

.ratio-content {
  --padding: calc(var(--space-unit-1));
  max-width: var(--layout-max-width);
  padding: var(--padding);
  width: 100%;
  position: absolute;
  inset: 0;
  margin: auto;
  display: flex;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.uppercase,
.upper {
  text-transform: uppercase;
}

.lowercase,
.lower {
  text-transform: lowercase;
}

.second-row {
  grid-row: 2;
}

.full-grid-item {
  grid-column: 1/-1 !important;
}

.text-center {
  text-align: center;
}

.image-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.redactor-content p + p {
  margin-top: 20px;
}
.redactor-content ul li {
  list-style: square;
}
.redactor-content p a, .redactor-content li a {
  color: var(--color, var(--color-black));
  position: relative;
  text-decoration: none;
}
.redactor-content p a:hover::after, .redactor-content p a.focus-visible::after, .redactor-content li a:hover::after, .redactor-content li a.focus-visible::after {
  width: 100%;
}
.redactor-content p a::after, .redactor-content li a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--color-red);
  bottom: -3px;
  left: 0;
  transition: width 0.4s ease;
}

.fake {
  position: absolute;
  inset: 0;
  height: 25%;
  width: 100%;
  background-color: var(--fake-background);
}
@media (min-width: 550px) {
  .fake {
    height: 50%;
  }
}
@media (min-width: 550px) {
  .fake.fake-content {
    height: 26%;
  }
}

.hidden {
  display: none;
}

.grecaptcha-badge {
  bottom: 100px !important;
  z-index: 2;
}
@media screen and (min-width: 1024px) {
  .grecaptcha-badge {
    bottom: 86px !important;
  }
}

figure iframe {
  width: 100%;
  max-width: 640px;
}

input:not([type=submit], [type=button]),
select,
textarea {
  display: block;
  width: 100%;
  background: none;
  border: none;
  font-weight: 700;
  color: var(--color-white);
  padding: 1rem 0 1.25rem;
  border-radius: 0;
}
input:not([type=submit], [type=button]):focus,
select:focus,
textarea:focus {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--color-white);
  opacity: 1;
}

textarea {
  resize: vertical;
  max-height: 150px;
}

label {
  display: block;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--color-white);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-black) inset;
  transition: background-color 0.1s ease-in-out 0s;
}

select {
  appearance: none;
}

.social-accounts ul.social-accounts-list {
  display: flex;
  flex-direction: row !important;
  height: auto;
  align-items: center;
  gap: 1.5rem;
  justify-content: start !important;
}
.social-accounts ul.social-accounts-list li {
  position: relative;
  --background: var(--color-black);
}
.social-accounts ul.social-accounts-list li:hover::after {
  transform: translateY(-10px);
}
.social-accounts ul.social-accounts-list li:hover a svg path {
  --fill: var(--color-red);
}
.social-accounts ul.social-accounts-list li::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  transform: translateY(40px);
  background: var(--background);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.social-accounts ul.social-accounts-list li a {
  height: 31px;
  width: 31px;
}
.social-accounts ul.social-accounts-list li a::after {
  content: unset !important;
}
.social-accounts ul.social-accounts-list li a svg {
  height: 100%;
  width: 100%;
  display: block;
}
.social-accounts ul.social-accounts-list li a svg path {
  fill: var(--fill, var(--color-black));
  transition: fill 0.3s ease;
}
.social-accounts ul li a {
  opacity: 1 !important;
}

.main-header {
  --background: var(--color-secondary-background);
  background: var(--background);
  display: grid;
  grid-template-rows: 80px;
  align-items: center;
  z-index: 9;
}
@media (min-width: 1024px) {
  .main-header {
    grid-template-rows: 100px;
  }
}
.main-header .container {
  --layout-max-width: 1520px;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
}
@media (min-width: 768px) {
  .main-header .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .main-header .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.main-header .container .grid-item {
  grid-column: span 2;
}
.main-header .container .full-grid-item {
  grid-column: span 4;
}
.main-header .main-navigation {
  grid-column-start: -1;
}
.main-header .logo-link svg {
  display: block;
}
.main-header #logo-nav-svg {
  width: 36px;
  height: 40px;
}
.main-header #logo-nav-svg path {
  fill: #ED1C24;
  transition: fill 0.2s linear;
}
.main-header #navigation-button path {
  transition: stroke 0.2s linear;
}
.main-header.icon__black #navigation-button path {
  stroke: black !important;
}
.main-header.icon__white #navigation-button path {
  stroke: #fff !important;
}
.main-header.logo__black #logo-nav-svg path {
  fill: #000;
}
.main-header.logo__white #logo-nav-svg path {
  fill: #fff;
}

.header-sticky {
  position: sticky;
  top: 0;
  --color-secondary-background: transparent !important;
}

.header-hidden {
  transform: translateY(-100%);
}

.header-animate {
  transition: transform 0.15s ease-out, background 0.15s ease-out;
}

menu-drawer {
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;
}
menu-drawer:focus {
  outline: none;
}
menu-drawer .menu-drawer {
  height: 100%;
  width: 100%;
}
menu-drawer .menu-drawer .container {
  --layout-max-width: 1520px;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  grid-template-rows: 100px 1fr;
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 768px) {
  menu-drawer .menu-drawer .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  menu-drawer .menu-drawer .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
menu-drawer .menu-drawer .container .grid-item {
  grid-column: span 2;
}
menu-drawer .menu-drawer .container .full-grid-item {
  grid-column: span 4;
}
menu-drawer .menu-drawer .container .logo-drawer {
  opacity: 0;
}
menu-drawer .menu-drawer .container .button {
  opacity: 0;
  grid-column-start: 4;
  justify-self: end;
}
@media (min-width: 768px) {
  menu-drawer .menu-drawer .container .button {
    grid-column-start: 8;
  }
}
@media (min-width: 1024px) {
  menu-drawer .menu-drawer .container .button {
    grid-column-start: 12;
  }
}
menu-drawer .menu-drawer .container .navigation-container {
  grid-column: 1/span 4;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5em;
}
@media (min-width: 768px) {
  menu-drawer .menu-drawer .container .navigation-container {
    grid-column: 1/span 8;
    grid-template-columns: 1fr 35%;
  }
}
@media (min-width: 1024px) {
  menu-drawer .menu-drawer .container .navigation-container {
    min-height: 80vh;
    grid-column: 1/span 12;
    grid-template-columns: 1fr 45%;
    align-items: center;
  }
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks {
  margin-top: 20px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-self: center;
  min-height: 70%;
  row-gap: 1.5em;
  column-gap: 0.7em;
}
@media (min-width: 768px) {
  menu-drawer .menu-drawer .container .navigation-container .info-blocks {
    column-gap: 0;
    margin-bottom: unset;
  }
}
@media (min-width: 1024px) {
  menu-drawer .menu-drawer .container .navigation-container .info-blocks {
    align-items: center;
  }
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .drawer-navigation-blocks {
  color: var(--color-black);
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .drawer-navigation-blocks a {
  color: inherit;
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .drawer-navigation-blocks h3 {
  margin-bottom: 10px;
  color: var(--color-white);
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .address-block a {
  --hover-line: var(--color-black);
}
@media (max-width: 374px) {
  menu-drawer .menu-drawer .container .navigation-container .info-blocks .address-block {
    grid-column: 1/span 4 !important;
  }
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .address-block:first-of-type {
  grid-column: 1;
}
@media (min-width: 768px) and (max-width: 1023px) {
  menu-drawer .menu-drawer .container .navigation-container .info-blocks .address-block:first-of-type {
    grid-column: 1/span 4;
  }
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .address-block + .address-block {
  grid-column: 2;
}
@media (min-width: 768px) and (max-width: 1023px) {
  menu-drawer .menu-drawer .container .navigation-container .info-blocks .address-block + .address-block {
    grid-column: 1/span 4;
  }
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .contact-block {
  grid-column: 1/span 4;
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .social-accounts {
  grid-column: 1/span 4;
}
menu-drawer .menu-drawer .container .navigation-container .info-blocks .drawer-navigation-blocks,
menu-drawer .menu-drawer .container .navigation-container .info-blocks .social-accounts {
  opacity: 0;
}
menu-drawer .menu-drawer .container .navigation-container ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (min-width: 1024px) {
  menu-drawer .menu-drawer .container .navigation-container ul {
    height: 80%;
  }
}
menu-drawer .menu-drawer .container .navigation-container ul li a,
menu-drawer .menu-drawer .container .navigation-container ul li span {
  color: var(--color-white);
  text-decoration: none;
  letter-spacing: 0.1em;
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li {
  display: block;
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item {
  display: inline-flex;
  align-items: start;
  will-change: transform;
  column-gap: 15px;
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item .hover,
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item .current {
  --translatey: 18px;
  display: inline-block;
  height: 2px;
  width: 15px;
  background: var(--color-white);
  transform: translateY(var(--translatey));
}
@media (min-width: 1024px) {
  menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item .hover,
  menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item .current {
    --translatey: 28px;
  }
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item .hover {
  --translatexy: -30px, 18px;
  transform: translate(--translatexy);
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease;
}
@media (min-width: 1024px) {
  menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item .hover {
    --translatexy: -30px, 28px;
  }
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item .text {
  transition: transform 0.2s ease;
  display: inline-block;
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item:is(a):hover .hover, menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item:is(a).focus-visible .hover {
  opacity: 1;
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item:is(a):hover .hover + .text, menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item:is(a).focus-visible .hover + .text {
  transform: translateX(0);
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item:is(a) .current + .text {
  transform: translateX(0);
}
menu-drawer .menu-drawer .container .navigation-container .nav-items li .nav-item:is(a) .hover + .text {
  transform: translateX(-30px);
}
menu-drawer .menu-drawer .container .navigation-container .social-accounts-list li::after {
  transform: translateY(10px);
}
menu-drawer .menu-drawer .container .navigation-container .social-accounts-list li a svg path {
  --fill: var(--color-white);
}
menu-drawer .menu-drawer .container .navigation-container .social-accounts-list li:hover::after {
  transform: translateY(-40px);
}
menu-drawer .menu-drawer .container .navigation-container .social-accounts-list li:hover a svg path {
  --fill: var(--color-black);
}
menu-drawer[show=true] {
  visibility: visible;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
}

.main-footer {
  --background: var(--color-white);
  background: var(--background);
  position: relative;
  padding-bottom: 40px;
  padding-top: 80px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .main-footer {
    padding-bottom: 100px;
    padding-top: 140px;
  }
}
.main-footer .cropmarks {
  width: 60%;
  height: calc(100% - 50px);
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
}
@media (min-width: 768px) {
  .main-footer .cropmarks {
    width: 70%;
    height: calc(100% - 100px);
    display: block;
  }
}
@media (min-width: 1024px) {
  .main-footer .cropmarks {
    width: 80%;
  }
}
@media (min-width: 1300px) {
  .main-footer .cropmarks {
    width: 85%;
  }
}
.main-footer .cropmarks .top {
  transform: translateY(0);
}
.main-footer .container {
  --gutter-mobile: 30px 16px;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
}
@media (min-width: 768px) {
  .main-footer .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .main-footer .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.main-footer .container .grid-item {
  grid-column: span 2;
}
.main-footer .container .full-grid-item {
  grid-column: span 4;
}
.main-footer .container.bottom-links {
  padding-bottom: var(--space-mobile-2);
  position: relative;
}
@media (min-width: 1024px) {
  .main-footer .container.bottom-links {
    align-items: center;
  }
}
.main-footer .container.bottom-links .item {
  overflow: hidden;
}
.main-footer .container.bottom-links .item:first-of-type {
  grid-column: 1/-1;
  order: 2;
}
@media (min-width: 1024px) {
  .main-footer .container.bottom-links .item:first-of-type {
    grid-column: 1/span 2;
  }
}
.main-footer .container.bottom-links .item:nth-child(2) {
  order: 3;
  grid-column: 1/-1;
}
@media (min-width: 1024px) {
  .main-footer .container.bottom-links .item:nth-child(2) {
    grid-column: 3/span 2;
  }
}
.main-footer .container.bottom-links .item:nth-child(3) {
  grid-column: 1/-1;
  color: var(--color-gray);
  order: 4;
}
@media (min-width: 1024px) {
  .main-footer .container.bottom-links .item:nth-child(3) {
    grid-row: 2;
    grid-column: 1/span 2;
  }
}
.main-footer .container.bottom-links .item:nth-child(4) {
  grid-column: 1/-1;
  color: var(--color-gray);
  order: 5;
  color: var(--color-gray);
}
@media (min-width: 1024px) {
  .main-footer .container.bottom-links .item:nth-child(4) {
    grid-row: 2;
    grid-column: 3/span 3;
  }
}
.main-footer .container.bottom-links .item:nth-child(4) a {
  color: var(--color-gray) !important;
  color: var(--color, var(--color-black));
  position: relative;
  text-decoration: none;
}
.main-footer .container.bottom-links .item:nth-child(4) a:hover::after, .main-footer .container.bottom-links .item:nth-child(4) a.focus-visible::after {
  width: 100%;
}
.main-footer .container.bottom-links .item:nth-child(4) a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--color-red);
  bottom: -3px;
  left: 0;
  transition: width 0.4s ease;
}
@media (min-width: 1024px) {
  .main-footer .container.bottom-links .item {
    order: unset !important;
  }
}
.main-footer .container.bottom-links a {
  --color: var(--color-black);
  color: var(--color);
  color: var(--color, var(--color-black));
  position: relative;
  text-decoration: none;
}
.main-footer .container.bottom-links a:hover::after, .main-footer .container.bottom-links a.focus-visible::after {
  width: 100%;
}
.main-footer .container.bottom-links a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--color-red);
  bottom: -3px;
  left: 0;
  transition: width 0.4s ease;
}
.main-footer .container.bottom-links .social-accounts {
  grid-column: 1/span 2;
  order: 1;
}
@media (min-width: 1024px) {
  .main-footer .container.bottom-links .social-accounts {
    grid-column: 7/span 3;
    order: unset;
  }
}
.main-footer .container.bottom-links .social-accounts .social-accounts-list {
  margin: 0;
  padding: 0;
}
.main-footer .container.bottom-links .social-accounts .social-accounts-list li {
  --background: var(--color-red);
}
.main-footer .container.bottom-links .social-accounts .social-accounts-list li a {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.main-footer .grid-item .footer-title {
  margin-bottom: 20px;
  color: var(--color-tertiary-text);
}
.main-footer .grid-item ul {
  padding: 0;
  margin: 0;
}
.main-footer .grid-item ul li + li {
  margin-top: 10px;
}
.main-footer .grid-item ul li {
  overflow: hidden;
}
.main-footer .grid-item ul li a {
  --color: inherit;
  color: var(--color);
  position: relative;
  text-decoration: none;
}
.main-footer .grid-item ul li a:hover::after, .main-footer .grid-item ul li a.focus-visible::after {
  transform: translateX(0);
}
.main-footer .grid-item ul li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--hover-line, var(--color-red));
  bottom: -3px;
  left: 0;
  transform: translateX(-101%);
  transition: transform 0.4s ease;
}
.main-footer .grid-item.logo-footer {
  grid-column: span 4;
}
@media (min-width: 768px) {
  .main-footer .grid-item.logo-footer {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  .main-footer .grid-item.address-block {
    grid-row: 2;
  }
  .main-footer .grid-item.address-block:first-of-type {
    grid-column: 1/span 2;
  }
  .main-footer .grid-item.address-block:last-of-type {
    grid-column: 3/span 2;
  }
}
@media (min-width: 1024px) {
  .main-footer .grid-item.footer-block[data-footer-nav="1"] {
    grid-column: 7/span 2;
  }
  .main-footer .grid-item.footer-block[data-footer-nav="2"] {
    grid-column: 9/span 2;
  }
  .main-footer .grid-item.footer-block[data-footer-nav="3"] {
    grid-column: 11/span 2;
  }
}

.hero {
  background-color: var(--color-black);
  position: relative;
  display: grid;
  align-items: center;
}
.hero > video {
  position: absolute;
  height: 100vh;
  width: 100vw;
  inset: 0;
  object-fit: cover;
  display: none;
  z-index: 5;
  display: none;
}
.hero > video.active {
  display: block;
}
.hero .h1 {
  --color-primary-text: var(--color-red);
}
.hero .container {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  grid-template-rows: 340px 370px;
  position: relative;
}
@media (min-width: 768px) {
  .hero .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .hero .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.hero .container .grid-item {
  grid-column: span 2;
}
.hero .container .full-grid-item {
  grid-column: span 4;
}
@media (max-width: 1023px) {
  .hero .container {
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .hero .container {
    padding-top: calc(var(--space-unit-1) * 3);
    padding-bottom: calc(var(--space-unit-1) * 3);
    grid-template-rows: 720px;
  }
}
.hero .container .grid-item-heading,
.hero .container .grid-item-video {
  grid-column: 1/-1;
}
.hero .container .grid-item-video {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.hero .container .grid-item-video:hover button {
  opacity: 1;
}
.hero .container .grid-item-video img,
.hero .container .grid-item-video video {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  object-fit: cover;
}
@media (min-width: 1024px) {
  .hero .container .grid-item-video img.mobile,
  .hero .container .grid-item-video video.mobile {
    display: none;
  }
}
@media (max-width: 1024px) {
  .hero .container .grid-item-video img {
    display: none;
  }
}
@media (min-width: 1024px) {
  .hero .container .grid-item-video {
    display: block;
    grid-column-start: 6;
    grid-column-end: 13;
  }
  .hero .container .grid-item-video img {
    border-radius: 20px;
  }
  .hero .container .grid-item-video .button {
    display: none;
  }
}
.hero .container .grid-item-video .play-button {
  z-index: 2;
}
.hero .container .grid-item-heading {
  padding-left: calc(var(--space-unit-1));
  padding-right: calc(var(--space-unit-1));
}
@media (min-width: 1024px) {
  .hero .container .grid-item-heading {
    grid-column: 1/span 8;
    position: absolute;
    z-index: 1;
  }
}
.hero .container .grid-item-heading .h1 {
  max-width: 670px;
}
@media (min-width: 1024px) {
  .hero .container .grid-item-heading .h1 {
    max-width: unset;
  }
}
@media (min-width: 1024px) {
  .hero .container .grid-item-heading .h1 {
    transform: translateY(-50px);
  }
}
.hero .container .grid-item-heading .h1 + button {
  display: none;
}
@media (min-width: 1024px) {
  .hero .container .grid-item-heading .h1 + button {
    display: inline-block;
  }
}

.what-we-do {
  background-color: var(--color-white);
  overflow: hidden;
}
.what-we-do .wrapper {
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-3);
  padding-left: calc(var(--space-unit-1));
  padding-right: calc(var(--space-unit-1));
}
@media (min-width: 1024px) {
  .what-we-do .wrapper {
    padding-top: var(--space-desktop-7);
    padding-bottom: var(--space-desktop-8);
  }
}
.what-we-do .container {
  padding: 0;
  position: relative;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
}
@media (min-width: 768px) {
  .what-we-do .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .what-we-do .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.what-we-do .container .grid-item {
  grid-column: span 2;
}
.what-we-do .container .full-grid-item {
  grid-column: span 4;
}
@media (max-width: 1500px) and (min-width: 768px) {
  .what-we-do .container {
    max-width: 80%;
  }
}
.what-we-do .container .top-right {
  display: none;
  grid-row: 1;
  justify-self: end;
  grid-column: 1/-1;
}
@media (min-width: 1024px) {
  .what-we-do .container .top-right {
    display: block;
  }
}
.what-we-do .container .grid-item {
  grid-column: span 5;
}
@media (min-width: 1024px) {
  .what-we-do .container .grid-item {
    grid-column: span 5;
  }
}
@media (min-width: 1024px) {
  .what-we-do .container .grid-item.left-start {
    grid-row: 1;
    grid-column: 1/span 6;
  }
}
.what-we-do .container .grid-item.red-banner {
  grid-row: 2;
}
@media (min-width: 1024px) {
  .what-we-do .container .grid-item.red-banner {
    grid-column: 1/span 6;
  }
}
.what-we-do .container .grid-item.grid-content {
  grid-row: 3;
}
@media (min-width: 768px) {
  .what-we-do .container .grid-item.grid-content {
    grid-row: 2;
  }
}
@media (min-width: 1024px) {
  .what-we-do .container .grid-item.grid-content {
    grid-column: 7/span 6;
  }
}
.what-we-do .container .red-banner {
  background-color: var(--color-red);
  color: var(--color-white);
  padding: 25px 20px;
}

.services {
  background-color: var(--section-background);
  position: relative;
}
.services .container {
  position: relative;
  z-index: 1;
}
.services .container:not(.title-container, .offset-left-title) {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  padding-bottom: var(--space-mobile-2);
}
@media (min-width: 768px) {
  .services .container:not(.title-container, .offset-left-title) {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .services .container:not(.title-container, .offset-left-title) {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.services .container:not(.title-container, .offset-left-title) .grid-item {
  grid-column: span 2;
}
.services .container:not(.title-container, .offset-left-title) .full-grid-item {
  grid-column: span 4;
}
@media (min-width: 1024px) {
  .services .container:not(.title-container, .offset-left-title) {
    padding-bottom: var(--space-desktop-4);
  }
}
.services .container:not(.title-container, .offset-left-title) .grid-item:not(.service-card) {
  grid-column: span 4;
}
@media (min-width: 550px) {
  .services .container:not(.title-container, .offset-left-title) .grid-item:not(.service-card) {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  .services .container:not(.title-container, .offset-left-title) .grid-item:not(.service-card) {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  .services .container:not(.title-container, .offset-left-title) .grid-item:not(.service-card) {
    grid-column: span 6;
  }
}
.services .title-container {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  color: var(--color-white);
}
@media (min-width: 768px) {
  .services .title-container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .services .title-container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.services .title-container .grid-item {
  grid-column: span 2;
}
.services .title-container .full-grid-item {
  grid-column: span 4;
}
.services .line {
  height: 1px;
  background-color: var(--color-white);
  width: 100%;
  grid-column: 1/-1;
}
.services.left-title .container {
  align-items: start;
}
.services.left-title .container .title-grid-item {
  --color: var(--color-white);
  color: var(--color);
  grid-column: 1/-1;
  margin-bottom: 60px;
}
@media (min-width: 1024px) {
  .services.left-title .container .title-grid-item {
    grid-column: 1/span 1;
  }
}
@media (min-width: 1024px) {
  .services.left-title .container .title-grid-item {
    margin-bottom: unset;
  }
}
@media (min-width: 1024px) {
  .services.left-title .container .title-grid-item span {
    display: block;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    transform-origin: center;
  }
}
.services.left-title .container .grid-item:not(.service-card).card {
  grid-column: span 4;
}
.services.left-title .container .grid-item:not(.service-card).card:first-of-type {
  grid-row: 2;
}
@media (min-width: 768px) {
  .services.left-title .container .grid-item:not(.service-card).card:first-of-type {
    grid-column: 1/span 4;
  }
}
@media (min-width: 1024px) {
  .services.left-title .container .grid-item:not(.service-card).card:first-of-type {
    grid-column: 2/span 5;
  }
}
.services.left-title .container .grid-item:not(.service-card).card:last-of-type {
  grid-row: 3;
}
@media (min-width: 768px) {
  .services.left-title .container .grid-item:not(.service-card).card:last-of-type {
    grid-column: 5/span 4;
  }
}
@media (min-width: 1024px) {
  .services.left-title .container .grid-item:not(.service-card).card:last-of-type {
    grid-column: 7/span 5;
  }
}
@media (min-width: 768px) {
  .services.left-title .container .grid-item:not(.service-card).card {
    grid-row: 2 !important;
  }
}
@media (min-width: 1024px) {
  .services.left-title .container .grid-item:not(.service-card).card {
    grid-row: 1 !important;
  }
}
.services .fake-two {
  background-color: var(--fake-background);
  height: 75%;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.featured-entries {
  background-color: var(--section-background);
  position: relative;
}
.featured-entries .container {
  position: relative;
  z-index: 1;
}
.featured-entries .container:not(.title-container, .offset-left-title) {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  padding-bottom: var(--space-mobile-2);
}
@media (min-width: 768px) {
  .featured-entries .container:not(.title-container, .offset-left-title) {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .featured-entries .container:not(.title-container, .offset-left-title) {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.featured-entries .container:not(.title-container, .offset-left-title) .grid-item {
  grid-column: span 2;
}
.featured-entries .container:not(.title-container, .offset-left-title) .full-grid-item {
  grid-column: span 4;
}
@media (min-width: 1024px) {
  .featured-entries .container:not(.title-container, .offset-left-title) {
    padding-bottom: var(--space-desktop-4);
  }
}
.featured-entries .container:not(.title-container, .offset-left-title) .grid-item:not(.service-card) {
  grid-column: span 4;
}
@media (min-width: 550px) {
  .featured-entries .container:not(.title-container, .offset-left-title) .grid-item:not(.service-card) {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  .featured-entries .container:not(.title-container, .offset-left-title) .grid-item:not(.service-card) {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  .featured-entries .container:not(.title-container, .offset-left-title) .grid-item:not(.service-card) {
    grid-column: span 6;
  }
}
.featured-entries .title-container {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  color: var(--color-white);
}
@media (min-width: 768px) {
  .featured-entries .title-container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .featured-entries .title-container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.featured-entries .title-container .grid-item {
  grid-column: span 2;
}
.featured-entries .title-container .full-grid-item {
  grid-column: span 4;
}
.featured-entries .line {
  height: 1px;
  background-color: var(--color-white);
  width: 100%;
  grid-column: 1/-1;
}
.featured-entries.left-title .container {
  align-items: start;
}
.featured-entries.left-title .container .title-grid-item {
  --color: var(--color-white);
  color: var(--color);
  grid-column: 1/-1;
  margin-bottom: 60px;
}
@media (min-width: 1024px) {
  .featured-entries.left-title .container .title-grid-item {
    grid-column: 1/span 1;
  }
}
@media (min-width: 1024px) {
  .featured-entries.left-title .container .title-grid-item {
    margin-bottom: unset;
  }
}
@media (min-width: 1024px) {
  .featured-entries.left-title .container .title-grid-item span {
    display: block;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    transform-origin: center;
  }
}
.featured-entries.left-title .container .grid-item:not(.service-card).card {
  grid-column: span 4;
}
.featured-entries.left-title .container .grid-item:not(.service-card).card:first-of-type {
  grid-row: 2;
}
@media (min-width: 768px) {
  .featured-entries.left-title .container .grid-item:not(.service-card).card:first-of-type {
    grid-column: 1/span 4;
  }
}
@media (min-width: 1024px) {
  .featured-entries.left-title .container .grid-item:not(.service-card).card:first-of-type {
    grid-column: 2/span 5;
  }
}
.featured-entries.left-title .container .grid-item:not(.service-card).card:last-of-type {
  grid-row: 3;
}
@media (min-width: 768px) {
  .featured-entries.left-title .container .grid-item:not(.service-card).card:last-of-type {
    grid-column: 5/span 4;
  }
}
@media (min-width: 1024px) {
  .featured-entries.left-title .container .grid-item:not(.service-card).card:last-of-type {
    grid-column: 7/span 5;
  }
}
@media (min-width: 768px) {
  .featured-entries.left-title .container .grid-item:not(.service-card).card {
    grid-row: 2 !important;
  }
}
@media (min-width: 1024px) {
  .featured-entries.left-title .container .grid-item:not(.service-card).card {
    grid-row: 1 !important;
  }
}
.featured-entries .fake-two {
  background-color: var(--fake-background);
  height: 75%;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.faqs {
  background: var(--color-red);
  position: relative;
}
@media (min-width: 768px) {
  .faqs {
    padding-top: var(--space-desktop-3);
    padding-bottom: var(--space-desktop-3);
  }
}
.faqs #r {
  position: absolute;
  display: none;
  clip-path: path("m487.24,611.65h-108.35c-22.04,0-33.06-8.27-45.91-27.55l-99.17-147.84c-8.27-11.02-10.1-21.12-10.1-46.83v-73.46c0-16.53,5.51-26.63,26.63-26.63h18.37c44.99,0,87.23-31.22,87.23-74.38,0-47.75-36.73-78.05-84.48-78.05h-122.13v448.11c0,21.12-10.1,26.63-26.63,26.63H31.79c-16.53,0-25.71-5.51-25.71-26.63V34.98c0-16.53,4.59-26.63,25.71-26.63h231.4c100.09,0,144.17,20.2,182.73,58.77,33.98,33.06,53.26,85.4,53.26,144.17,0,96.42-70.71,163.45-127.64,182.73l129.47,188.24c8.26,12.86,3.67,29.38-13.78,29.38h0Z");
}
@media (min-width: 768px) {
  .faqs #r {
    display: block;
    top: 50px;
    bottom: 0;
    width: 600px;
    height: 620px;
    background-attachment: fixed;
  }
}
@media (min-width: 1340px) {
  .faqs #r {
    right: 60%;
  }
}
@media (max-width: 1340px) and (min-width: 1024px) {
  .faqs #r {
    right: 55%;
  }
}
@media (max-width: 1024px) {
  .faqs #r {
    right: 40%;
  }
}
@media (min-width: 1024px) {
  .faqs {
    background-size: 40%;
  }
}
@media (min-width: 1280px) {
  .faqs {
    background-size: unset;
  }
}
.faqs .faqs-container {
  color: var(--color-white);
}
.faqs .container {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
}
@media (min-width: 768px) {
  .faqs .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .faqs .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.faqs .container .grid-item {
  grid-column: span 2;
}
.faqs .container .full-grid-item {
  grid-column: span 4;
}
.faqs .container .asset-container {
  display: none;
}
@media (min-width: 768px) {
  .faqs .container .asset-container {
    display: block;
    grid-column: 1/span 4;
  }
}
.faqs .container .faqs-container {
  grid-column: 1/span 4;
}
@media (min-width: 768px) {
  .faqs .container .faqs-container {
    grid-column: 6/span 8;
  }
}
@media (min-width: 1024px) {
  .faqs .container .faqs-container {
    grid-column: 7/span 8;
  }
}
@media (min-width: 1280px) {
  .faqs .container .faqs-container {
    grid-column: 6/span 8;
  }
}

.our-process {
  background: var(--color-white);
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-2);
  position: relative;
}
@media (min-width: 768px) {
  .our-process {
    padding-bottom: var(--space-mobile-5);
  }
}
@media (min-width: 1024px) {
  .our-process {
    padding-top: var(--space-desktop-8);
    padding-bottom: var(--space-desktop-8);
  }
}
.our-process > div {
  position: relative;
}
.our-process > div > video {
  position: absolute;
  height: 100vh;
  width: 100vw;
  inset: 0;
  object-fit: cover;
  display: none;
  z-index: 10;
}
.our-process > div > video.active {
  display: block;
}
.our-process .crop-mark {
  position: absolute;
  right: 35px;
  top: 85px;
}
.our-process .video-heading {
  color: var(--color-red);
}
.our-process .container {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
}
@media (min-width: 768px) {
  .our-process .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .our-process .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.our-process .container .grid-item {
  grid-column: span 2;
}
.our-process .container .full-grid-item {
  grid-column: span 4;
}
.our-process .container.heading-container {
  padding-bottom: 0;
  margin-bottom: -50px;
  position: relative;
  z-index: 1;
}
.our-process .container.heading-container > * {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .our-process .container.heading-container {
    z-index: 2;
    left: 50px;
    margin-bottom: -135px;
  }
}
@media (min-width: 1024px) {
  .our-process .container.heading-container {
    margin-bottom: -165px;
  }
}
@media (min-width: 1470px) {
  .our-process .container.heading-container {
    margin-bottom: -195px;
  }
}
.our-process .container.heading-container .cropmarks {
  width: 0px;
  height: 0px;
  left: unset;
  top: 0;
  right: 30px;
}
@media (min-width: 640px) {
  .our-process .container.heading-container .cropmarks {
    right: 100px;
  }
}
.our-process .container.heading-container .cropmarks > div.left {
  left: 0;
  transform: translate(calc(-100% + 2px), calc(-100% + 2px));
}
.our-process .container.heading-container .cropmarks > div.left:before {
  height: 60%;
}
.our-process .container.heading-container .cropmarks > div.left:after {
  width: 60%;
}
.our-process .container.heading-container .cropmarks > div.right {
  right: 0;
  transform: translate(100%, 100%);
}
.our-process .container.heading-container .cropmarks > div.right:before {
  width: 60%;
}
.our-process .container.heading-container .cropmarks > div.right:after {
  height: 60%;
}
@media (max-width: 767px) {
  .our-process .container.no-margin {
    padding-left: 0;
    padding-right: 0;
  }
}
.our-process .container .grid-item-video-mobile {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .our-process .container .grid-item-video-mobile {
    display: none;
  }
}
.our-process .container .grid-item-video-mobile .video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.our-process .container .grid-item-video-mobile .video::after {
  content: "";
  padding-top: 100%;
  width: 100%;
  display: block;
}
.our-process .container .grid-item-video-mobile .video video {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.our-process .container .grid-item-video-mobile .button {
  position: absolute;
  margin: auto;
  z-index: 1;
}
.our-process .container .grid-item-video-desktop {
  display: none;
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .our-process .container .grid-item-video-desktop {
    display: block;
  }
}
.our-process .container .grid-item-video-desktop .video {
  position: relative;
}
.our-process .container .grid-item-video-desktop .video .screen-container {
  display: none;
}
@media (min-width: 768px) {
  .our-process .container .grid-item-video-desktop .video .screen-container {
    display: block;
    inset: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }
  .our-process .container .grid-item-video-desktop .video .screen-container .screen {
    border-radius: 20px;
    position: absolute;
    background-color: var(--color-red);
    transform: translate(10%, 15%);
    width: 100%;
    height: 100%;
  }
}
.our-process .container .grid-item-video-desktop img {
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .our-process .container .grid-item-video-desktop img {
    border-radius: 20px;
  }
}
@media (min-width: 1024px) {
  .our-process .container .grid-item-video-desktop img {
    height: 600px;
  }
}
.our-process .container .grid-item-video-desktop .button {
  position: absolute;
  z-index: 3;
  left: 0;
}
@media (min-width: 768px) {
  .our-process .container .grid-item-video-desktop .button {
    left: 40px;
    bottom: 40px;
  }
}

button,
.button,
[type=button] {
  all: unset;
  --outline: -webkit-focus-ring-color auto 1px;
}
button.focus-visible,
.button.focus-visible,
[type=button].focus-visible {
  outline: var(--outline);
}

.button:not(.navigation-button) {
  all: unset;
  display: inline-block;
  background-color: var(--color-white);
  transform: perspective(1px) translateZ(0);
  position: relative;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  padding: 10px 16px;
  font-family: var(--button-text-font-family);
  font-size: var(--button-text-font-size);
  line-height: var(--button-text-line-height);
  overflow: hidden;
}
.button:not(.navigation-button)::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--color-red);
  border-radius: 20px;
  transform: translateX(-100%);
  transform-origin: 0 50%;
  opacity: 0;
  z-index: -1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.button:not(.navigation-button):hover::after, .button:not(.navigation-button).focus-visible::after {
  transform: translateX(0);
  opacity: 1;
}

.button.call-to-action,
.button.cookies-button {
  background-color: var(--button-background);
  color: var(--button-color);
}
.button.call-to-action.focus-visible, .button.call-to-action:hover,
.button.cookies-button.focus-visible,
.button.cookies-button:hover {
  color: var(--button-hover-color);
}
.button.call-to-action.focus-visible::after, .button.call-to-action:hover::after,
.button.cookies-button.focus-visible::after,
.button.cookies-button:hover::after {
  background-color: var(--button-hover-background);
}

button.navigation-button {
  position: relative;
  display: grid;
  place-items: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
button.navigation-button .icon {
  transform-origin: center;
  position: absolute;
  display: block;
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
button.navigation-button .icon path {
  stroke: var(--color-white);
}
button.navigation-button.focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 1px;
}
button.navigation-button:hover .icon {
  transform: scale(1.2);
}

button.navigation-button[aria-expanded=false] .icon-close,
button.navigation-button[data-expanded=false] .icon-close {
  opacity: 0;
  transform: scale(0);
}

button.navigation-button[aria-expanded=true] .icon-close,
button.navigation-button[data-expanded=true] .icon-close {
  opacity: 0;
  transform: scale(0);
}

button.close-lightbox {
  right: 25px;
  top: 25px;
}
@media (min-width: 768px) {
  button.close-lightbox {
    right: 50px;
    top: 50px;
  }
}

button.close-video {
  right: 50px;
  top: 50px;
}

button.close-video,
button.close-lightbox,
button.close-cookies {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
  pointer-events: none;
  --background: var(--color-red);
  background-color: var(--background);
  border-radius: 50%;
  cursor: pointer;
  --pseudo-background: var(--color-white);
}
button.close-video.active,
button.close-lightbox.active,
button.close-cookies.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
button.close-video:hover,
button.close-lightbox:hover,
button.close-cookies:hover {
  transform: scale(1.1);
}
button.close-video:after, button.close-video:before,
button.close-lightbox:after,
button.close-lightbox:before,
button.close-cookies:after,
button.close-cookies:before {
  position: absolute;
  inset: 0;
  margin: auto;
  content: "";
  height: 2px;
  width: 70%;
  transform-origin: center;
  background-color: var(--pseudo-background);
  border-radius: 3px;
}
button.close-video:after,
button.close-lightbox:after,
button.close-cookies:after {
  transform: rotate(45deg);
}
button.close-video:before,
button.close-lightbox:before,
button.close-cookies:before {
  transform: rotate(-45deg);
}

.scroll-button {
  display: none;
  height: 95px;
  width: 95px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transform: translateY(12px);
  right: 2%;
  bottom: 10%;
}
@media (min-width: 768px) {
  .scroll-button {
    display: block;
  }
}
.scroll-button:hover .scroll-button-overlay, .scroll-button.focus-visible .scroll-button-overlay {
  --background: var(--color-red);
}
.scroll-button .scroll-button-overlay {
  --background: transparent;
  display: inline-block;
  background-color: var(--background);
  position: absolute;
  inset: 0;
  transition: background 0.3s ease;
  opacity: 0.2;
}
.scroll-button > svg {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  z-index: 1;
}
.scroll-button.to-top {
  opacity: 1;
  transform: translateY(0);
  bottom: 0;
  right: 0;
  top: 0;
}

.marquee-component {
  --color-primary-background: var(--color-red);
  background-color: var(--color-primary-background);
  max-width: 100%;
  overflow: hidden;
}
.marquee-component .dot {
  display: inline-block;
  height: 6px;
  width: 6px;
  background-color: var(--color-black);
  margin: 0 2rem;
}
.marquee-component .marquee-element {
  display: inline-block;
  padding: 25px 0;
}
.marquee-component > * {
  --color-primary-text: var(--color-white);
  color: var(--color-primary-text);
}

.address-block {
  overflow: hidden;
}
.address-block a {
  --color: var(--color-black);
  color: var(--color);
  --hover-line: var(--color-red);
  position: relative;
  text-decoration: none;
}
.address-block a:hover::after, .address-block a.focus-visible::after {
  transform: translateX(0);
}
.address-block a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--hover-line, var(--color-red));
  bottom: -3px;
  left: 0;
  transform: translateX(-101%);
  transition: transform 0.4s ease;
}

.cropmarks {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.cropmarks.show-topLeft div:nth-child(1) {
  display: block;
}
.cropmarks.show-topRight div:nth-child(2) {
  display: block;
}
.cropmarks.show-bottomLeft div:nth-child(3) {
  display: block;
}
.cropmarks.show-bottomRight div:nth-child(4) {
  display: block;
}
.cropmarks.inset div.left {
  left: 0;
}
.cropmarks.inset div.right {
  right: 0;
}
.cropmarks div {
  width: 40px;
  height: 40px;
  position: absolute;
  display: none;
}
.cropmarks div:before, .cropmarks div:after {
  position: absolute;
  background-color: var(--color-red);
  content: "";
  z-index: 1;
}
.cropmarks div.left {
  left: -100px;
}
.cropmarks div.top {
  top: 0;
  transform: translateY(-100%);
}
.cropmarks div.right {
  right: -100px;
}
.cropmarks div.bottom {
  bottom: 0;
  transform: translateY(100%);
}
.cropmarks div.left.top:before {
  right: 0;
  left: unset;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 55%;
  width: 2px;
}
.cropmarks div.left.top:after {
  right: 0;
  left: 0;
  top: unset;
  bottom: 0;
  margin: 0 auto;
  height: 2px;
  width: 55%;
}
.cropmarks div.left.bottom:before {
  right: 0;
  left: 0;
  top: 0;
  bottom: unset;
  margin: 0 auto;
  height: 2px;
  width: 55%;
}
.cropmarks div.left.bottom:after {
  right: 0;
  left: unset;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 55%;
  width: 2px;
}
.cropmarks div.right.top:before {
  right: unset;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 55%;
  width: 2px;
}
.cropmarks div.right.top:after {
  right: 0;
  left: 0;
  top: unset;
  bottom: 0;
  margin: 0 auto;
  height: 2px;
  width: 55%;
}
.cropmarks div.right.bottom:before {
  right: 0;
  left: 0;
  top: 0;
  bottom: unset;
  margin: 0 auto;
  height: 2px;
  width: 55%;
}
.cropmarks div.right.bottom:after {
  right: unset;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 55%;
  width: 2px;
}

.service-intro {
  background-color: var(--color-white);
  overflow: hidden;
}
.service-intro.packaging-intro .wrapper {
  padding-bottom: var(--space-mobile-1);
  padding-top: var(--space-mobile-2);
}
.service-intro .wrapper {
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-5);
  padding-left: calc(var(--space-unit-1));
  padding-right: calc(var(--space-unit-1));
}
@media (min-width: 1024px) {
  .service-intro .wrapper {
    padding-top: var(--space-desktop-4);
  }
}
.service-intro .container {
  padding: 0;
  position: relative;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
}
@media (min-width: 768px) {
  .service-intro .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .service-intro .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.service-intro .container .grid-item {
  grid-column: span 2;
}
.service-intro .container .full-grid-item {
  grid-column: span 4;
}
.service-intro .container h1 {
  --color: var(--color-red);
  color: var(--color);
  text-align: center;
  margin: 25px auto 15px;
}
.service-intro .container .grid-item-text .content {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.service-intro .container .scroll-to-container {
  text-align: center;
}
.service-intro .container .scroll-to-container .scroll-to {
  width: 120px;
  height: 120px;
  margin-top: 25px;
  background-color: var(--color-black);
  border-radius: 50%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  cursor: pointer;
  transition: transform 0.4s ease;
}
.service-intro .container .scroll-to-container .scroll-to:hover {
  transform: scale(1.05);
}
@media (min-width: 768px) {
  .service-intro .container .scroll-to-container .scroll-to {
    width: 140px;
    height: 140px;
  }
}
.service-intro .container .scroll-to-container .scroll-to svg {
  margin: 10px 0;
}
.service-intro .container .scroll-to-container .scroll-to span {
  font-size: 14px;
  font-family: var(--font-syne);
}
@media (min-width: 768px) {
  .service-intro .container .scroll-to-container .scroll-to span {
    font-size: 15px;
  }
}

.service-banner {
  position: relative;
  overflow: unset;
}
.service-banner > figure {
  overflow: hidden;
}
.service-banner::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
@media (min-width: 1024px) {
  .service-banner::before {
    content: unset;
  }
}
@media (min-width: 1024px) {
  .service-banner {
    max-height: var(--desktop-max-height);
  }
}
.service-banner figure {
  display: contents;
}
.service-banner .image-banner {
  position: absolute;
  inset: 0;
}
@media (min-width: 1024px) {
  .service-banner .image-banner {
    position: relative;
    max-height: var(--desktop-max-height);
  }
}
.service-banner .ratio-content {
  z-index: 2;
  align-items: end;
  height: 100%;
  --padding: 0 calc(var(--space-unit-1));
}
.service-banner .ratio-content .banner-caption {
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 10px;
  margin-bottom: 35px;
}

.service-description {
  background-color: var(--color-black);
  color: var(--color-white);
}
.service-description .container {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
  row-gap: 50px;
  padding-top: var(--space-mobile-4);
  padding-bottom: var(--space-mobile-1);
}
@media (min-width: 768px) {
  .service-description .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .service-description .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.service-description .container .grid-item {
  grid-column: span 2;
}
.service-description .container .full-grid-item {
  grid-column: span 4;
}
@media (min-width: 1024px) {
  .service-description .container {
    padding-top: var(--space-desktop-7);
  }
}
.service-description .container h2,
.service-description .container h3 {
  color: var(--color-red);
  margin-bottom: 30px;
}
.service-description .container .grid-item-content {
  grid-column: span 4;
}
@media (min-width: 768px) {
  .service-description .container .grid-item-content {
    grid-column: 1/span 4;
  }
}
@media (min-width: 1024px) {
  .service-description .container .grid-item-content {
    grid-column: 2/span 6;
  }
}
.service-description .container .grid-item-content .redactor-content {
  max-width: 550px;
}
.service-description .container .grid-item-offerings {
  grid-column: span 4;
}
@media (min-width: 768px) {
  .service-description .container .grid-item-offerings {
    grid-column: 6/span 3;
  }
}
@media (min-width: 1024px) {
  .service-description .container .grid-item-offerings {
    grid-column: 9/span 3;
  }
}
@media (min-width: 768px) {
  .service-description .container .grid-item-offerings {
    padding-top: 70px;
    position: sticky;
    top: 3rem;
  }
}
.service-description .container .grid-item-offerings .list li + li {
  margin-top: 20px;
}

section.about-hero .h1,
section.about-hero p,
section.about-hero span {
  color: var(--color-white);
}
@media screen and (min-width: 1024px) {
  section.about-hero h1 {
    padding-bottom: var(--space-mobile-1);
  }
}
section.about-hero .container .about-hero-title {
  margin-top: var(--space-mobile-1);
  padding-bottom: var(--space-desktop-1);
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  section.about-hero .container .about-hero-content .grid-columns > div:first-child {
    grid-column: 1/span 4;
  }
  section.about-hero .container .about-hero-content .grid-columns > div:last-child {
    grid-column: 6/span 7;
  }
}
@media screen and (min-width: 1280px) and (min-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  section.about-hero .container .about-hero-content .grid-columns > div:first-child {
    grid-column: 1/span 3;
  }
  section.about-hero .container .about-hero-content .grid-columns > div:last-child {
    grid-column: 5/span 6;
  }
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date-and-image .about-hero-image {
  margin-bottom: var(--space-mobile-2);
  position: relative;
  overflow: hidden;
  border-radius: 0 2rem 2rem 0;
  transform: translateX(calc(var(--space-mobile-1) * -1));
  max-height: 521px;
}
@media screen and (min-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns .about-hero-date-and-image .about-hero-image {
    transform: translateX(-10rem);
    border-radius: 2rem;
    margin-right: -11rem;
    height: 521px;
  }
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date-and-image .about-hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns .about-hero-date-and-image .about-hero-image img {
    border-radius: 0 2rem 2rem 0;
  }
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date-and-image .about-hero-image img:not(.one) {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(100%);
}
@media (max-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns .about-hero-date-and-image .about-hero-image img:not(.one) {
    display: none;
  }
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date {
  display: flex;
  align-items: flex-end;
  padding-bottom: var(--space-mobile-1);
}
@media screen and (min-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns .about-hero-date {
    padding-bottom: var(--space-mobile-2);
  }
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date p {
  position: relative;
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date p span {
  display: inline-block;
  font-size: var(--h4-desktop-font-size);
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date p span.hidden-js {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateY(-100%);
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date .year-js {
  margin-left: 1rem;
  position: relative;
  text-align: center;
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-date .year-js::after {
  content: "";
  position: absolute;
  height: 0.8rem;
  width: 100%;
  background-color: var(--color-red);
  color: var(--color-red);
  bottom: -1rem;
  right: 0;
  z-index: 1;
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-paragraph {
  padding-bottom: var(--space-mobile-1);
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns .about-hero-paragraph {
    margin-top: var(--space-desktop-1);
  }
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-paragraph .about-hero-date {
  margin-top: 2rem;
}
@media screen and (min-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns .about-hero-paragraph .about-hero-date {
    display: none;
  }
}
section.about-hero .container .about-hero-content .grid-columns .about-hero-paragraph > p {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1024px) {
  section.about-hero .container .about-hero-content .grid-columns .about-hero-paragraph > p:not(.main) {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateY(-100%);
  }
}
section.about-hero .container .scroll-label {
  display: none;
  position: absolute;
  right: 0;
  transform: translateY(-200%);
  writing-mode: vertical-lr;
  height: 360px;
}
@media screen and (min-width: 1280px) {
  section.about-hero .container .scroll-label {
    display: flex;
    align-items: center;
  }
}
section.about-hero .container .scroll-label span,
section.about-hero .container .scroll-label svg {
  display: inline-block;
}
section.about-hero .container .scroll-label .arrow {
  height: 130px;
  display: flex;
  align-items: center;
}
section.about-hero .container .scroll-label .arrow .line {
  height: 100%;
  width: 2px;
  background-color: var(--color-red);
  animation: elastic 1.5s cubic-bezier(1, 0, 0, 1) infinite;
}
section.about-hero .container .scroll-label .arrow svg {
  margin-top: -8px;
  position: relative;
  z-index: 1;
}
section.about-hero .container .scroll-label.arrow svg .line {
  stroke-dashoffset: 0;
  animation: elastic 3s cubic-bezier(1, 0, 0, 1) infinite;
}
section.about-hero .container .scroll-label svg .line {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
section.about-hero .container .scroll-label span {
  margin-bottom: 15px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(-20%);
  }
  65% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes elastic {
  0% {
    transform-origin: 0% 0%;
    transform: scale(1, 0);
  }
  50% {
    transform-origin: 0% 0%;
    transform: scale(1, 1);
  }
  50.1% {
    transform-origin: 0% 100%;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: 0% 100%;
    transform: scale(1, 0);
  }
}
.call-to-action .container {
  padding: 0 0 var(--space-mobile-2) 0;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .call-to-action .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .call-to-action .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.call-to-action .container .grid-item {
  grid-column: span 2;
}
.call-to-action .container .full-grid-item {
  grid-column: span 4;
}
@media (min-width: 768px) {
  .call-to-action .container {
    padding-left: calc(var(--space-unit-1));
    padding-right: calc(var(--space-unit-1));
  }
}
@media (min-width: 1024px) {
  .call-to-action .container {
    padding-bottom: var(--space-desktop-4);
  }
}
.call-to-action .container .grid-cta {
  grid-column: 1/-1;
  padding: var(--space-mobile-2) calc(var(--space-unit-1));
  clip-path: inset(0 0 0 0);
  transition: clip-path 0.3s ease-out;
  background-color: var(--color-red);
  text-align: center;
}
@media (min-width: 1024px) {
  .call-to-action .container .grid-cta {
    padding: var(--space-desktop-3) calc(var(--space-unit-1));
    grid-column: 2/span 10;
  }
  .call-to-action .container .grid-cta:hover {
    clip-path: inset(7% 5% 7% 5%);
  }
  .call-to-action .container .grid-cta:has(.button.focus-visible) {
    clip-path: inset(7% 5% 7% 5%);
  }
}
.call-to-action .container .grid-cta h2 + .redactor-content {
  margin-top: 20px;
}
.call-to-action .container .grid-cta .redactor-content + .button {
  margin-top: 40px;
}
.call-to-action .container .grid-cta .redactor-content {
  color: var(--color-white);
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  section.team-members {
    padding-bottom: var(--space-desktop-6);
  }
}
section.team-members .container .team-members-heading {
  padding-bottom: var(--space-mobile-2);
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  section.team-members .container .team-members-heading {
    padding-bottom: var(--space-desktop-5);
  }
}
section.team-members .container .team-members-heading h2 {
  color: var(--color-white);
  font-family: var(--h4-font-family);
  background-color: var(--color-black);
  display: inline-flex;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 1rem;
}
@media screen and (min-width: 1024px) {
  section.team-members .container .team-members-heading h2 {
    margin-right: 4rem;
  }
}
section.team-members .container .team-members-heading span {
  display: flex;
  height: 2px;
  width: 100%;
  background-color: var(--color-red);
  color: var(--color-red);
}
@media screen and (min-width: 768px) {
  section.team-members .container .team-members-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}
@media screen and (min-width: 1280px) {
  section.team-members .container .team-members-cards-wrapper {
    gap: calc(18rem - 40px);
  }
}
section.team-members .container .team-members-cards-wrapper .team-member-card:last-child {
  padding-bottom: 0;
}

section.our-services-cards {
  background-color: var(--color-white);
  position: relative;
}
section.our-services-cards .container:not(.title-container, .offset-left-title) {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  align-items: inherit;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  gap: 0 var(--gap);
  padding-top: var(--space-mobile-3);
}
@media (min-width: 768px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
section.our-services-cards .container:not(.title-container, .offset-left-title) .grid-item {
  grid-column: span 2;
}
section.our-services-cards .container:not(.title-container, .offset-left-title) .full-grid-item {
  grid-column: span 4;
}
@media (min-width: 768px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) {
    padding-top: var(--space-mobile-2);
  }
}
@media (min-width: 1024px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) {
    padding-top: 0;
    padding-bottom: var(--space-desktop-7);
  }
}
section.our-services-cards .container:not(.title-container, .offset-left-title) .title-grid-item {
  color: var(--color-white);
  grid-column: 1/-1;
  margin-bottom: 60px;
}
@media (min-width: 1024px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) .title-grid-item {
    grid-column: 1/span 1;
  }
}
@media (min-width: 1024px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) .title-grid-item {
    margin-bottom: unset;
  }
}
@media (min-width: 1024px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) .title-grid-item span {
    display: block;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    transform-origin: center;
  }
}
section.our-services-cards .container:not(.title-container, .offset-left-title) .grid-item {
  grid-column: span 4;
}
@media (min-width: 640px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) .grid-item {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) .grid-item {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  section.our-services-cards .container:not(.title-container, .offset-left-title) .grid-item {
    grid-column: span 5;
  }
}

section.simple-images {
  padding-bottom: var(--space-desktop-2);
}
@media screen and (max-width: 768px) {
  section.simple-images {
    overflow: scroll;
  }
  section.simple-images::-webkit-scrollbar {
    width: 0;
    background: transparent;
    /* make scrollbar transparent */
  }
}
@media screen and (min-width: 1024px) {
  section.simple-images {
    padding-bottom: var(--space-desktop-7);
  }
}
section.simple-images .simple-images-wrapper {
  white-space: nowrap;
}
@media screen and (min-width: 1720px) {
  section.simple-images .simple-images-wrapper {
    margin: 0 auto;
  }
}
section.simple-images .simple-images-wrapper > div {
  display: inline-block;
  width: 210px;
  height: 205px;
  margin-right: 40px;
  position: relative;
}
section.simple-images .simple-images-wrapper > div:first-child {
  margin-left: 40px;
}
@media screen and (min-width: 1024px) {
  section.simple-images .simple-images-wrapper > div {
    width: 510px;
    height: 505px;
    margin-right: calc(var(--layout-max-width) / 18);
  }
  section.simple-images .simple-images-wrapper > div:first-child {
    margin-left: calc(var(--layout-max-width) / 18);
  }
}
section.simple-images .simple-images-wrapper > div .button-modal-lightbox {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 1;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: none;
}
section.simple-images .simple-images-wrapper > div .button-modal-lightbox:hover {
  transform: scale(1.1);
}
section.simple-images .simple-images-wrapper > div .button-modal-lightbox.focus-visible {
  outline: 3px solid var(--color-white);
  outline-offset: 2px;
  transform: scale(1.1);
}
section.simple-images .simple-images-wrapper > div .button-modal-lightbox svg {
  pointer-events: none;
  position: relative;
  z-index: -1;
}
@media (min-width: 768px) {
  section.simple-images .simple-images-wrapper > div .button-modal-lightbox {
    display: block;
  }
}
section.simple-images .simple-images-wrapper img {
  border-radius: 2rem;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

section.case-study-hero {
  max-height: calc(90vh - 4.5rem);
  height: 90vh;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1024px) {
  section.case-study-hero {
    min-height: 800px;
  }
}
section.case-study-hero .container {
  --container-display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: unset;
  padding: 0;
  position: relative;
  z-index: 1;
  height: 100%;
}
section.case-study-hero .container .scroll-button-container {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 80px;
  max-width: var(--layout-max-width);
}
section.case-study-hero .container .case-study-hero-text {
  width: 100%;
  height: 4.5rem;
  color: var(--color-white);
  font-family: var(--h4-font-family);
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: var(--color-red);
}
@media screen and (min-width: 1720px) {
  section.case-study-hero .container .case-study-hero-text {
    padding: 0;
  }
}
section.case-study-hero .container .case-study-hero-text > div {
  max-width: var(--layout-max-width);
  width: 100%;
  margin: 0 auto;
  padding-left: calc(var(--space-unit-1));
  padding-right: calc(var(--space-unit-1));
}
section.case-study-hero img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
}

section.text-with-services-table {
  background-color: var(--color-white);
  padding-top: var(--space-desktop-2);
  padding-bottom: var(--space-mobile-2);
}
@media (min-width: 1024px) {
  section.text-with-services-table {
    padding-top: var(--space-desktop-6);
    padding-bottom: var(--space-desktop-5);
  }
}
section.text-with-services-table .container {
  padding: 0;
}
@media screen and (min-width: 1024px) {
  section.text-with-services-table .container {
    border-left: 2px solid var(--color-red);
  }
}
section.text-with-services-table .container .about-hero-title {
  padding-bottom: var(--space-desktop-3);
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  section.text-with-services-table .container .text-with-services-table-content .grid-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  section.text-with-services-table .container .text-with-services-table-content .grid-columns > div:first-child {
    grid-column: 2/span 6;
  }
  section.text-with-services-table .container .text-with-services-table-content .grid-columns > div:last-child {
    grid-column: 9/span 4;
  }
}
@media screen and (min-width: 1280px) and (min-width: 1024px) {
  section.text-with-services-table .container .text-with-services-table-content .grid-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  section.text-with-services-table .container .text-with-services-table-content .grid-columns > div:first-child {
    grid-column: 2/span 6;
  }
  section.text-with-services-table .container .text-with-services-table-content .grid-columns > div:last-child {
    grid-column: 9/span 4;
  }
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .redactor-paragraph {
  border-left: 4px solid var(--color-red);
  padding: calc(var(--space-unit-1));
}
@media screen and (min-width: 1024px) {
  section.text-with-services-table .container .text-with-services-table-content .grid-columns .redactor-paragraph {
    padding: 0;
    border: none;
  }
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper {
  background-color: var(--color-pink);
  padding: var(--space-mobile-1);
  margin-top: var(--space-mobile-2);
}
@media screen and (min-width: 1024px) {
  section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper {
    padding: var(--space-desktop-1);
    margin-top: 0;
    border-radius: 2rem 0 0 2rem;
  }
}
@media screen and (min-width: 1380px) {
  section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper {
    border-radius: 2rem;
  }
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper .text-button-text {
  color: var(--color-red);
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper .service-client-logo {
  display: flex;
  justify-content: center;
  padding: 6rem 0;
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper .service-client-logo img {
  /* max-height: 4rem; */
  max-width: 250px;
  /* width: 17rem; */
  width: auto;
  height: auto;
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper .services-list ul {
  padding-left: 0;
  margin: 0;
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper .services-list ul li {
  border-bottom: 1px solid var(--color-red);
  list-style-type: none;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper .services-list ul li:last-child {
  border: none;
  padding-bottom: 0;
}
section.text-with-services-table .container .text-with-services-table-content .grid-columns .text-with-services-table-wrapper .services-list ul li .icon-check {
  padding-right: 1rem;
}

.index-grid {
  --section-background: var(--color-black);
  background-color: var(--section-background);
  position: relative;
}
.index-grid::after {
  --height: 40vh;
  height: var(--height);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--color-white);
}
@media (min-width: 550px) {
  .index-grid::after {
    --height: 380px;
  }
}
@media (min-width: 1024px) {
  .index-grid::after {
    --height: 450px;
  }
}
@media (min-width: 1280px) {
  .index-grid::after {
    --height: 500px;
  }
}
@media (min-width: 1024px) {
  .index-grid {
    padding-top: var(--space-desktop-2);
  }
}
.index-grid .wrapper {
  padding: var(--space-mobile-2) var(--space-unit-1) 0;
}
@media (min-width: 1024px) {
  .index-grid .wrapper {
    padding: 50px;
    display: block;
  }
}
.index-grid .container {
  position: relative;
  z-index: 1;
}
.index-grid .container:is(.empty-container) {
  padding: 0;
}
.index-grid .container:is(.grid-index-container) {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
  --gap: var(--space-mobile-2) 20px !important;
  padding-top: 0;
}
@media (min-width: 768px) {
  .index-grid .container:is(.grid-index-container) {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .index-grid .container:is(.grid-index-container) {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.index-grid .container:is(.grid-index-container) .grid-item {
  grid-column: span 2;
}
.index-grid .container:is(.grid-index-container) .full-grid-item {
  grid-column: span 4;
}
@media (min-width: 768px) {
  .index-grid .container:is(.grid-index-container) {
    padding-bottom: var(--space-desktop-6);
  }
}
@media (min-width: 1024px) {
  .index-grid .container:is(.grid-index-container) {
    --gap: var(--space-desktop-6) 20px !important;
  }
}
@media (min-width: 1024px) {
  .index-grid .container:is(.grid-index-container) {
    padding-bottom: calc(var(--space-desktop-7) + var(--space-desktop-2));
  }
}
@media (min-width: 1024px) {
  .index-grid .container:is(.grid-index-container) .title-grid {
    padding-top: 50%;
    grid-column: 1/span 1 !important;
    grid-row: 1;
    align-self: start;
  }
}
.index-grid .container:is(.grid-index-container) .title-grid > div {
  text-align: center;
}
@media (min-width: 1024px) {
  .index-grid .container:is(.grid-index-container) .title-grid > div {
    width: 100%;
    transform: rotate(180deg);
    transform-origin: center;
    writing-mode: vertical-lr;
  }
}
.index-grid .container:is(.grid-index-container) .service-card {
  grid-column: span 4;
}
@media (min-width: 550px) {
  .index-grid .container:is(.grid-index-container) .service-card {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  .index-grid .container:is(.grid-index-container) .service-card {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  .index-grid .container:is(.grid-index-container) .service-card.card-odd {
    grid-column: 2/span 5;
  }
  .index-grid .container:is(.grid-index-container) .service-card.card-even {
    grid-column: 7/span 5;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}
@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}
.modal-lightbox {
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100vw;
  z-index: 11;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  transition: opacity 300ms cubic-bezier(0.39, 0.575, 0.565, 1), visibility 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
}
.modal-lightbox .modal-lightbox-overlay {
  position: fixed;
  inset: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.modal-lightbox .modal-lightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}
.modal-lightbox .close-lightbox.focus-visible {
  outline: 3px solid var(--color-white);
  outline-offset: 2px;
}
.modal-lightbox .modal-lightbox-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
}
.modal-lightbox .modal-lightbox-content img {
  max-width: 85%;
  object-fit: cover;
  height: auto;
  border-radius: 2rem;
  position: relative;
  z-index: 3;
}
@media (min-width: 768px) {
  .modal-lightbox .modal-lightbox-content img {
    max-width: 85vw;
  }
}
@media (min-width: 1024px) {
  .modal-lightbox .modal-lightbox-content img {
    max-height: 80vh;
  }
}

modal-lightbox {
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
}
modal-lightbox:focus {
  outline: none;
}

modal-lightbox[show=false] .modal-lightbox {
  opacity: 0;
  visibility: hidden;
  transition-delay: 300ms;
}
modal-lightbox[show=false] .modal-lightbox-content {
  opacity: 0;
  transition-delay: 0s;
}
modal-lightbox[show=false] button.close-lightbox {
  opacity: 0;
  visibility: hidden;
  transition-delay: 300ms;
}

modal-lightbox[show=true] {
  z-index: 12;
}
modal-lightbox[show=true] .modal-lightbox {
  opacity: 1;
  visibility: visible;
  transition-delay: 0ms;
}
modal-lightbox[show=true] .modal-lightbox-content {
  opacity: 1;
  transition-delay: 300ms !important;
}
modal-lightbox[show=true] button.close-lightbox {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.images-grid {
  background-color: var(--color-secondary-background);
  position: relative;
}
section.images-grid .container:not(.title-container, .offset-left-title) {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  align-items: inherit;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  section.images-grid .container:not(.title-container, .offset-left-title) {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  section.images-grid .container:not(.title-container, .offset-left-title) {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
section.images-grid .container:not(.title-container, .offset-left-title) .grid-item {
  grid-column: span 2;
}
section.images-grid .container:not(.title-container, .offset-left-title) .full-grid-item {
  grid-column: span 4;
}
@media (max-width: 640px) {
  section.images-grid .container:not(.title-container, .offset-left-title) {
    gap: 0;
  }
}
@media (min-width: 1024px) {
  section.images-grid .container:not(.title-container, .offset-left-title) {
    padding-bottom: var(--space-desktop-7);
    gap: var(--gap);
  }
}
section.images-grid .container:not(.title-container, .offset-left-title) .title-grid-item {
  color: var(--color-dark);
  grid-column: 1/-1;
  margin-bottom: 60px;
}
@media (min-width: 1024px) {
  section.images-grid .container:not(.title-container, .offset-left-title) .title-grid-item {
    grid-column: 1/span 1;
  }
}
@media (min-width: 1024px) {
  section.images-grid .container:not(.title-container, .offset-left-title) .title-grid-item {
    margin-bottom: unset;
  }
}
@media (min-width: 1024px) {
  section.images-grid .container:not(.title-container, .offset-left-title) .title-grid-item span {
    display: block;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    transform-origin: center;
  }
}
section.images-grid .container:not(.title-container, .offset-left-title) .grid-item {
  grid-column: span 4;
}
@media (min-width: 550px) {
  section.images-grid .container:not(.title-container, .offset-left-title) .grid-item {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  section.images-grid .container:not(.title-container, .offset-left-title) .grid-item {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  section.images-grid .container:not(.title-container, .offset-left-title) .grid-item {
    grid-column: span 5;
  }
}

section.paragraph-block {
  background-color: var(--color-white);
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-2);
}
@media screen and (min-width: 1024px) {
  section.paragraph-block {
    padding-bottom: var(--space-desktop-4);
    padding-top: var(--space-desktop-4);
  }
}
section.paragraph-block.blog-entries-item {
  padding-bottom: var(--space-mobile-2);
}
@media (min-width: 1024px) {
  section.paragraph-block.blog-entries-item {
    padding-top: var(--space-desktop-6);
    padding-bottom: var(--space-desktop-7);
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  section.paragraph-block.full-width .container .grid-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  section.paragraph-block.full-width .container .grid-columns > div:first-child {
    grid-column: 2/span 6;
  }
}
@media screen and (min-width: 1280px) and (min-width: 1024px) {
  section.paragraph-block.full-width .container .grid-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  section.paragraph-block.full-width .container .grid-columns > div:first-child {
    grid-column: 2/span 11;
  }
}
section.paragraph-block .container {
  border-left: 4px solid var(--color-red);
}
@media screen and (min-width: 1024px) {
  section.paragraph-block .container {
    padding: 0;
    border-left: 2px solid var(--color-red);
  }
}
@media screen and (min-width: 1024px) and (min-width: 1024px) {
  section.paragraph-block .container .grid-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  section.paragraph-block .container .grid-columns > div:first-child {
    grid-column: 2/span 6;
  }
}
@media screen and (min-width: 1280px) and (min-width: 1024px) {
  section.paragraph-block .container .grid-columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  section.paragraph-block .container .grid-columns > div:first-child {
    grid-column: 2/span 6;
  }
}

section.landscape-image-block {
  background-color: var(--color-white);
  padding-top: 0;
  padding-bottom: 0;
}

section.case-study-banner {
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-3);
}
@media (min-width: 1024px) {
  section.case-study-banner {
    padding-top: 0;
    padding-bottom: var(--space-desktop-8);
  }
}
section.case-study-banner.blog-entries-item {
  margin-top: 0;
  padding-top: 0;
  background-color: var(--color-white);
  padding-bottom: var(--space-mobile-3);
}
@media (min-width: 1024px) {
  section.case-study-banner.blog-entries-item {
    padding-bottom: var(--space-desktop-8);
  }
}
section.case-study-banner.blog-entries-item > div {
  padding-top: var(--space-mobile-2);
}
@media (min-width: 1024px) {
  section.case-study-banner.blog-entries-item > div {
    padding-top: 0;
  }
}

.blog-post-hero {
  background-color: var(--color-white);
  padding-bottom: 0;
}
@media (min-width: 1024px) {
  .blog-post-hero {
    padding-bottom: 8rem;
  }
}
.blog-post-hero .wrapper {
  background-color: var(--color-black);
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-3);
  padding-left: calc(var(--space-unit-1));
  padding-right: calc(var(--space-unit-1));
}
@media (min-width: 1024px) {
  .blog-post-hero .wrapper {
    padding-top: var(--space-desktop-4);
    padding-bottom: 0;
  }
}
.blog-post-hero .typewriter-js span {
  color: var(--color-white);
}
.blog-post-hero .container {
  padding: 0;
  position: relative;
}
.blog-post-hero .container h1 {
  --color: var(--color-red);
  color: var(--color);
  text-align: center;
  margin: 25px auto 15px;
}
.blog-post-hero .container .grid-item-text .content {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.blog-post-hero .container .grid-item-text .content p {
  color: var(--color-white);
}
.blog-post-hero .container .blog-post-image {
  display: flex;
  justify-content: center;
}
.blog-post-hero .container .blog-post-image img {
  transform: translateY(10rem);
  border-radius: 2rem;
}
@media (min-width: 1024px) {
  .blog-post-hero .container .blog-post-image img {
    max-width: 750px;
    height: auto;
  }
}

section.case-study-banner {
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-3);
}
@media (min-width: 1024px) {
  section.case-study-banner {
    padding-top: 0;
    padding-bottom: var(--space-desktop-8);
  }
}
section.case-study-banner.blog-entries-item {
  margin-top: 0;
  padding-top: 0;
  background-color: var(--color-white);
  padding-bottom: var(--space-mobile-3);
}
@media (min-width: 1024px) {
  section.case-study-banner.blog-entries-item {
    padding-bottom: var(--space-desktop-8);
  }
}
section.case-study-banner.blog-entries-item > div {
  padding-top: var(--space-mobile-2);
}
@media (min-width: 1024px) {
  section.case-study-banner.blog-entries-item > div {
    padding-top: 0;
  }
}

section.case-study-banner {
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-3);
}
@media (min-width: 1024px) {
  section.case-study-banner {
    padding-top: 0;
    padding-bottom: var(--space-desktop-8);
  }
}
section.case-study-banner.blog-entries-item {
  margin-top: 0;
  padding-top: 0;
  background-color: var(--color-white);
  padding-bottom: var(--space-mobile-3);
}
@media (min-width: 1024px) {
  section.case-study-banner.blog-entries-item {
    padding-bottom: var(--space-desktop-8);
  }
}
section.case-study-banner.blog-entries-item > div {
  padding-top: var(--space-mobile-2);
}
@media (min-width: 1024px) {
  section.case-study-banner.blog-entries-item > div {
    padding-top: 0;
  }
}

.hero-intro {
  --background: var(--color-white);
  background-color: var(--background);
  overflow: hidden;
}
.hero-intro .wrapper {
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-5);
  padding-left: calc(var(--space-unit-1));
  padding-right: calc(var(--space-unit-1));
}
@media (min-width: 1024px) {
  .hero-intro .wrapper {
    padding-top: var(--space-desktop-4);
  }
}
.hero-intro .container {
  padding: 0;
  position: relative;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
}
@media (min-width: 768px) {
  .hero-intro .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .hero-intro .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.hero-intro .container .grid-item {
  grid-column: span 2;
}
.hero-intro .container .full-grid-item {
  grid-column: span 4;
}
.hero-intro .container .subheading {
  --color: inherit;
  color: var(--color);
}
.hero-intro .container h1 {
  --color: var(--color-red);
  color: var(--color);
  text-align: center;
  margin: 25px auto 15px;
}
.hero-intro .container .grid-item-text .content {
  --color: inherit;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  color: var(--color);
}

.contact-form {
  --background: var(--color-black);
  background-color: var(--background);
}
.contact-form .container {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
  padding-top: 0;
  padding-bottom: var(--space-desktop-6);
}
@media (min-width: 768px) {
  .contact-form .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  .contact-form .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
.contact-form .container .grid-item {
  grid-column: span 2;
}
.contact-form .container .full-grid-item {
  grid-column: span 4;
}
@media (min-width: 1024px) {
  .contact-form .container {
    padding-bottom: calc(var(--space-desktop-8) + 75px);
  }
}
.contact-form .container .contact-form-container {
  grid-column: span 4;
  color: var(--color-white);
}
@media (min-width: 768px) {
  .contact-form .container .contact-form-container {
    grid-column: 2/span 6;
  }
}
@media (min-width: 1024px) {
  .contact-form .container .contact-form-container {
    grid-column: 3/span 8;
  }
}
.contact-form .container .contact-form-container .grid-form {
  display: grid;
  --columns: 1fr;
  --gap: var(--space-mobile-1) var(--gutter-mobile);
  grid-template-columns: var(--columns);
  gap: var(--gap);
}
@media (min-width: 640px) {
  .contact-form .container .contact-form-container .grid-form {
    --columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .contact-form .container .contact-form-container .grid-form {
    --gap: var(--space-desktop-2) var(--gutter-desktop);
  }
}
.contact-form .container .contact-form-container form .field-group {
  overflow: hidden;
}
.contact-form .container .contact-form-container form .field-group > * + * {
  margin-top: 10px;
}
.contact-form .container .contact-form-container form .field-group label {
  color: var(--color-gray);
}
.contact-form .container .contact-form-container form .field {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 2px solid var(--color-red);
}
.contact-form .container .contact-form-container form .field:focus-within::after {
  transform: translateX(0);
}
.contact-form .container .contact-form-container form .field::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-white);
  transform: translateX(-100%);
  transition: transform 0.4s ease;
}
.contact-form .container .contact-form-container form .field select {
  position: relative;
  z-index: 1;
}
.contact-form .container .contact-form-container form .field select + svg {
  position: absolute;
  right: 10px;
  margin-bottom: 5px;
  display: block;
}
.contact-form .container .contact-form-container form .required-mark {
  color: var(--color-red);
}
.contact-form .container .contact-form-container form .submit-button {
  color: var(--color-black);
}
.contact-form .container .contact-form-container form .submit-button [disabled] {
  pointer-events: none;
}

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  visibility: hidden;
  transition: transform 0.3s ease, visibility 0.3s ease;
  z-index: 5;
}
.cookies.active {
  visibility: visible;
  transform: translateY(0);
}
.cookies > div {
  --background: var(--color-red);
  background: var(--background);
}
.cookies .container {
  position: relative;
}
.cookies .cookies-content {
  display: grid;
  gap: 1.5rem;
}
@media (min-width: 640px) {
  .cookies .cookies-content {
    grid-template-columns: 1fr auto;
    align-items: center;
  }
}
.cookies .cookies-content:not(button) {
  color: var(--color-white);
}
.cookies .cookies-content .close-cookies {
  right: 10px;
  top: 10px;
}

section.double-images-block {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  background: var(--color-secondary);
}
section.double-images-block .container {
  padding-top: 0;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);
  z-index: 1;
}
@media (min-width: 768px) {
  section.double-images-block .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  section.double-images-block .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
section.double-images-block .container .grid-item {
  grid-column: span 2;
}
section.double-images-block .container .full-grid-item {
  grid-column: span 4;
}
@media (min-width: 1024px) {
  section.double-images-block .container {
    max-height: var(--desktop-max-height);
  }
}
section.double-images-block .container .grid-item {
  grid-column: span 4;
  position: relative;
}
@media (min-width: 550px) {
  section.double-images-block .container .grid-item {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  section.double-images-block .container .grid-item {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  section.double-images-block .container .grid-item {
    grid-column: span 6;
  }
}

section.full-image-block {
  background-color: var(--color-tertiary-background);
}
section.full-image-block .single-image-with-caption {
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-2);
}
@media screen and (min-width: 1024px) {
  section.full-image-block .single-image-with-caption {
    padding-bottom: var(--space-desktop-5);
    padding-top: 0;
  }
}

section.oEmbed {
  background-color: var(--color-black);
}
section.oEmbed.dynamic {
  background-color: var(--color-white);
}
section.oEmbed.dynamic .container {
  padding-top: var(--space-mobile-1);
  padding-bottom: var(--space-mobile-1);
}
@media (min-width: 640px) {
  section.oEmbed.dynamic .container {
    padding-bottom: var(--space-mobile-4);
  }
}
section.oEmbed .container {
  padding-top: var(--space-mobile-1);
  padding-bottom: var(--space-mobile-1);
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
}
@media (min-width: 768px) {
  section.oEmbed .container {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  section.oEmbed .container {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
section.oEmbed .container .grid-item {
  grid-column: span 2;
}
section.oEmbed .container .full-grid-item {
  grid-column: span 4;
}
section.oEmbed .container.full {
  max-width: unset;
  padding-left: 0;
  padding-right: 0;
}
section.oEmbed .container.full .embed-container {
  grid-column: 1/span 12;
}
@media (min-width: 1024px) {
  section.oEmbed .container {
    padding-top: var(--space-mobile-4);
    padding-bottom: var(--space-desktop-7);
  }
}
section.oEmbed .embed-container {
  --video--width: 640;
  --video--height: 360;
  grid-column: 1/span 10;
  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
}
@media (min-width: 1024px) {
  section.oEmbed .embed-container {
    grid-column: 2/span 10;
  }
}
section.oEmbed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-container.service-embed {
  --video--width: 640;
  --video--height: 360;
  margin: 3rem 0;
  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
}
.embed-container.service-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

section.packaging-pannels .wrapper {
  padding-top: var(--space-mobile-2);
  padding-bottom: 0;
  position: relative;
}
@media (max-width: 768px) {
  section.packaging-pannels .wrapper {
    padding-bottom: 0;
  }
}
section.packaging-pannels .wrapper #dots-nav {
  right: 40px;
  top: 50vh;
  z-index: 5;
}
@media (max-width: 768px) {
  section.packaging-pannels .wrapper #dots-nav {
    display: none;
  }
}
section.packaging-pannels .panel {
  position: relative;
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: start;
  gap: var(--gap);
  padding-left: calc(var(--space-unit-1));
  padding-right: calc(var(--space-unit-1));
  padding-top: 490px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  overflow: hidden;
}
@media (min-width: 768px) {
  section.packaging-pannels .panel {
    --grid: var(--grid-md);
  }
}
@media (min-width: 1024px) {
  section.packaging-pannels .panel {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}
section.packaging-pannels .panel .grid-item {
  grid-column: span 2;
}
section.packaging-pannels .panel .full-grid-item {
  grid-column: span 4;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel {
    padding-top: 320px;
  }
}
section.packaging-pannels .panel .container {
  display: block;
}
section.packaging-pannels .panel .container > :last-child {
  padding-bottom: 250px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .container > :last-child {
    padding-bottom: 150px;
  }
}
section.packaging-pannels .panel .panel-number {
  position: absolute;
  top: 85px;
  left: 135px;
  border-radius: 50%;
  border: 2px solid;
  font-family: var(--font-six-caps);
  font-size: 57px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 135px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .panel-number {
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100px;
    height: 100px;
    font-size: 38px;
    top: 60px;
  }
}
section.packaging-pannels .panel .package-img-container {
  position: absolute;
  top: 300px;
  width: 1000px;
  height: 500px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .package-img-container {
    top: 220px;
    width: 100vw;
    height: 300px;
  }
}
section.packaging-pannels .panel .package-img-container img {
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  opacity: 0.8;
}
section.packaging-pannels .panel .package-img-container img.hidden-js {
  opacity: 0;
}
section.packaging-pannels .panel .vertical-header {
  position: absolute;
  top: 400px;
  left: 40px;
}
section.packaging-pannels .panel .vertical-header p {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}
@media (max-width: 1500px) {
  section.packaging-pannels .panel .vertical-header {
    display: none;
  }
}
section.packaging-pannels .panel .panel-header {
  text-transform: uppercase;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .panel-header {
    text-align: center;
  }
}
section.packaging-pannels .panel .panel-content {
  margin-top: 700px;
  position: relative;
  max-width: 330px;
  z-index: 1;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .panel-content {
    margin-top: 200px;
  }
}
section.packaging-pannels .panel .panel-content h5 {
  padding-bottom: 75px;
  margin: 0;
}
section.packaging-pannels .panel .panel-content .case-studies {
  display: flex;
  padding-top: 100px;
  gap: 20px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .panel-content .case-studies {
    padding-top: 50px;
  }
}
section.packaging-pannels .panel .panel-content .case-studies > div {
  width: 95px;
  height: 95px;
  position: relative;
  overflow: hidden;
}
section.packaging-pannels .panel .panel-content .case-studies > div:hover .arrow {
  top: 0;
}
section.packaging-pannels .panel .panel-content .case-studies img {
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
section.packaging-pannels .panel .panel-content .case-studies button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}
section.packaging-pannels .panel .panel-content .case-studies .arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: var(--color-black);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  inset: 0;
  top: 125%;
  margin: auto;
  transition: top 0.3s ease-out;
}
@media (max-width: 640px) {
  section.packaging-pannels .panel .panel-content .case-studies .arrow {
    display: none;
  }
}
section.packaging-pannels .panel .panel-content .case-studies .arrow svg {
  width: 8px;
  height: 13px;
}
section.packaging-pannels .panel .quote {
  margin: 0 auto;
  margin-top: 400px;
  width: 100%;
  max-width: 1140px;
  text-align: center;
  position: relative;
  z-index: 11;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .quote {
    margin-top: 120px;
  }
}
section.packaging-pannels .panel .quote > p {
  font-size: 40px;
  font-family: var(--font-syne);
  font-weight: 400;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .quote > p {
    font-size: var(--subtitle-mobile-font-size);
  }
}
section.packaging-pannels .panel .quote > p strong {
  font-weight: inherit;
  background-color: var(--color-red);
}
section.packaging-pannels .panel .quote .author {
  margin-top: 100px;
  text-align: center;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel .quote .author {
    margin-top: 70px;
  }
}
section.packaging-pannels .panel .quote .author img {
  margin-bottom: 20px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: inline-block;
}
section.packaging-pannels .panel .quote .author p {
  margin: 0;
}
section.packaging-pannels .panel .quote .author .name {
  color: var(--color-red);
  font-style: normal;
  font-size: 24px;
}
section.packaging-pannels .panel .quote .author .position {
  color: var(--color-gray);
  font-size: 1rem;
  margin-top: 10px;
  text-decoration: underline;
}

section.packaging-pannels .panel.one {
  background-color: var(--color-red);
  color: var(--color-white);
}
section.packaging-pannels .panel.one::after {
  position: absolute;
  content: "";
  bottom: -150px;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: var(--color-red);
}
section.packaging-pannels .panel.one .package-img-container img {
  width: 450px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.one .package-img-container img {
    width: 350px;
  }
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.one .package-img-container img {
    width: 200px;
  }
}
section.packaging-pannels .panel.one .canvas-container {
  position: absolute;
  top: 150px;
  width: 500px;
  height: 500px;
  left: 30px;
  right: 0;
  margin: 0 auto;
}
@media (min-width: 768px) {
  section.packaging-pannels .panel.one .canvas-container {
    top: 200px;
    left: 0;
    width: 800px;
    height: 8000px;
  }
}
@media (min-width: 1024px) {
  section.packaging-pannels .panel.one .canvas-container {
    width: 1000px;
    height: 1000px;
  }
}
section.packaging-pannels .panel.one .canvas-container canvas {
  width: inherit;
  height: inherit;
  transform: rotate(-45deg);
  opacity: 0.7;
}

section.packaging-pannels .panel.two {
  z-index: 1;
  background-color: var(--color-black);
  color: var(--color-white);
}
section.packaging-pannels .panel.two .package-img-container {
  top: 280px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.two .package-img-container {
    top: 250px;
  }
}
section.packaging-pannels .panel.two .package-img-container img {
  width: 400px;
}
section.packaging-pannels .panel.two .package-img-container img:not(.hidden-js) {
  opacity: 0.6;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.two .package-img-container img {
    width: 200px;
  }
}

section.packaging-pannels .panel {
  margin-top: -50px;
}
section.packaging-pannels .panel.three {
  z-index: 3;
  background-color: var(--color-white);
  color: var(--color-black);
}
section.packaging-pannels .panel.three .package-img-container {
  top: 450px;
  width: 850px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.three .package-img-container {
    width: 430px;
    top: 250px;
  }
}
@media (max-width: 640px) {
  section.packaging-pannels .panel.three .package-img-container {
    width: 350px;
    top: 300px;
  }
}
section.packaging-pannels .panel.three .package-img-container img.one {
  width: 450px;
  left: 0;
  right: unset;
  z-index: 2;
}
section.packaging-pannels .panel.three .package-img-container img.two {
  width: 350px;
  top: 100px;
  right: 0;
  left: unset;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.three .package-img-container img.one {
    width: 250px;
  }
  section.packaging-pannels .panel.three .package-img-container img.two {
    width: 150px;
    top: 50px;
  }
}
@media (max-width: 640px) {
  section.packaging-pannels .panel.three .package-img-container img.one {
    width: 200px;
  }
  section.packaging-pannels .panel.three .package-img-container img.two {
    width: 100px;
    top: 50px;
  }
}

section.packaging-pannels .panel.four {
  z-index: 4;
  background-color: var(--color-red);
  color: var(--color-white);
}
section.packaging-pannels .panel.four .package-img-container {
  width: 580px;
  top: 400px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.four .package-img-container {
    top: 250px;
    width: 300px;
  }
}
section.packaging-pannels .panel.four .package-img-container img.one {
  width: 250px;
  left: 0;
  right: unset;
  z-index: 2;
}
section.packaging-pannels .panel.four .package-img-container img.two {
  width: 300px;
  top: -50px;
  right: 0;
  left: unset;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.four .package-img-container img.one {
    width: 120px;
  }
  section.packaging-pannels .panel.four .package-img-container img.two {
    width: 140px;
    top: -20px;
  }
}

section.packaging-pannels .panel.five {
  z-index: 4;
  background-color: var(--color-black);
  color: var(--color-white);
  padding-bottom: 200px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.five {
    padding-bottom: 0px;
  }
}
section.packaging-pannels .panel.five .package-img-container {
  width: 580px;
  top: 400px;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.five .package-img-container {
    top: 250px;
    width: 280px;
  }
}
section.packaging-pannels .panel.five .package-img-container img.one {
  width: 280px;
  left: 0;
  top: -100px;
  right: unset;
  z-index: 2;
}
section.packaging-pannels .panel.five .package-img-container img.two {
  width: 250px;
  right: 0;
  left: unset;
}
@media (max-width: 768px) {
  section.packaging-pannels .panel.five .package-img-container img.one {
    width: 120px;
    top: 0px;
  }
  section.packaging-pannels .panel.five .package-img-container img.two {
    width: 100px;
  }
}

.service:not(.card-link),
.featured-entry {
  display: inline-block;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
}
.service:not(.card-link) img,
.featured-entry img {
  width: 100%;
  transition: transform 0.4s ease;
}
.service:not(.card-link):hover img, .service:not(.card-link).hover img,
.featured-entry:hover img,
.featured-entry.hover img {
  transform: scale(1.1);
}
.service:not(.card-link):hover .badge, .service:not(.card-link).hover .badge,
.featured-entry:hover .badge,
.featured-entry.hover .badge {
  transform: translateY(0);
  opacity: 1;
}
.service:not(.card-link):hover svg, .service:not(.card-link).hover svg,
.featured-entry:hover svg,
.featured-entry.hover svg {
  transform: translateX(0);
}
.service:not(.card-link)::after,
.featured-entry::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
.service:not(.card-link) .layer,
.featured-entry .layer {
  position: absolute;
  inset: 0;
  background: linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0) 100%);
  width: 100%;
  height: 100%;
}
.service:not(.card-link) .badge,
.featured-entry .badge {
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: inline-block;
  color: var(--color-white);
  background: var(--color-black);
  z-index: 1;
  padding: 8px 40px;
  border-radius: 20px;
}
.service:not(.card-link) .badge,
.service:not(.card-link) svg,
.featured-entry .badge,
.featured-entry svg {
  transition-property: transform;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}
.service:not(.card-link) .badge,
.featured-entry .badge {
  transform: translateY(300%);
}
.service:not(.card-link) svg,
.featured-entry svg {
  transform: translateX(-600%);
  position: absolute;
  inset: 0;
  margin: auto;
  z-index: 1;
}

.accordion-heading {
  padding: 40px 0;
}

.accordion-line {
  height: 1px;
  width: 100%;
  background-color: var(--color-white);
}
.accordion-line:last-of-type {
  display: none;
}
@media (min-width: 768px) {
  .accordion-line:last-of-type {
    display: block;
  }
}

.accordion-control {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}
.accordion-control.focus-visible {
  outline-offset: 4px;
}
.accordion-control span {
  max-width: 85%;
  font-weight: normal;
}
.accordion-control svg {
  transition: opacity 0.3s ease;
}
.accordion-control svg path {
  transition: transform 0.3s ease;
  transform-origin: center;
}
.accordion-control[aria-expanded=false] svg {
  opacity: 0.7;
}
.accordion-control[aria-expanded=false] svg path:nth-child(2) {
  transform: rotate(0deg);
  transform-origin: center;
}
.accordion-control[aria-expanded=true] svg path:nth-child(2) {
  transform: rotate(90deg);
}

.accordion-panel {
  display: block;
}
.accordion-panel p {
  padding-bottom: 40px;
}

div.team-member-card {
  padding-bottom: var(--space-mobile-2);
}
@media screen and (max-width: 767px) {
  div.team-member-card:nth-child(even) {
    display: flex;
    justify-content: flex-end;
  }
  div.team-member-card:nth-child(even) .team-member-card-wrapper {
    max-width: 70%;
  }
}
@media screen and (max-width: 767px) and (min-width: 768px) {
  div.team-member-card:nth-child(even) .team-member-card-wrapper {
    max-width: 31%;
  }
}
div.team-member-card .team-member-card-wrapper {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  div.team-member-card .team-member-card-wrapper {
    padding-bottom: 0;
  }
}
div.team-member-card .team-member-card-wrapper .team-member-card-image {
  width: 169px;
  height: 169px;
  display: inline-block;
}
@media screen and (min-width: 1024px) {
  div.team-member-card .team-member-card-wrapper .team-member-card-image {
    max-width: 268px;
    width: 100%;
    height: auto;
  }
}
div.team-member-card .team-member-card-wrapper .team-member-card-image .team-member-images-wrapper {
  width: 169px;
  height: 169px;
  margin-right: 10px;
  position: relative;
}
@media screen and (min-width: 1024px) {
  div.team-member-card .team-member-card-wrapper .team-member-card-image .team-member-images-wrapper {
    width: 250px;
    height: 250px;
  }
}
div.team-member-card .team-member-card-wrapper .team-member-card-image .team-member-images-wrapper .team-member-main-image, div.team-member-card .team-member-card-wrapper .team-member-card-image .team-member-images-wrapper .team-member-secondary-image {
  position: absolute;
  width: 169px;
  height: 169px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  border-radius: 50%;
  background-size: cover;
}
@media screen and (min-width: 1024px) {
  div.team-member-card .team-member-card-wrapper .team-member-card-image .team-member-images-wrapper .team-member-main-image, div.team-member-card .team-member-card-wrapper .team-member-card-image .team-member-images-wrapper .team-member-secondary-image {
    width: 250px;
    height: 250px;
  }
}
div.team-member-card .team-member-card-wrapper .team-member-card-text {
  position: relative;
  padding-left: 1.5rem;
  max-width: 18rem;
  margin-top: var(--space-mobile-1);
  display: inline-block;
}
@media screen and (min-width: 1024px) {
  div.team-member-card .team-member-card-wrapper .team-member-card-text {
    max-width: none;
    margin-top: var(--space-desktop-1);
  }
}
div.team-member-card .team-member-card-wrapper .team-member-card-text .h4 {
  color: var(--color-red);
  font-family: var(--h4-font-family);
  padding-bottom: 1rem;
}
div.team-member-card .team-member-card-wrapper .team-member-card-text .team-member-card-position {
  color: var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
  width: max-content;
  margin-bottom: 1rem;
}
div.team-member-card .team-member-card-wrapper .team-member-card-text p {
  color: var(--color-white);
}
div.team-member-card .team-member-card-wrapper .team-member-card-text::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: var(--color-red);
  color: var(--color-red);
  top: 0;
  left: 0;
  z-index: 1;
}

.our-services-card .h2 {
  color: var(--color-red);
  padding-top: var(--space-mobile-1);
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 1024px) {
  .our-services-card .h2 {
    padding-top: var(--space-desktop-2);
    max-width: 95%;
  }
}
.our-services-card .our-services-card-description {
  padding-bottom: var(--space-mobile-1);
}
@media screen and (min-width: 768px) {
  .our-services-card .our-services-card-description {
    max-width: 90%;
  }
}
@media screen and (min-width: 1024px) {
  .our-services-card .our-services-card-description {
    max-width: 80%;
  }
}
.our-services-card .buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
@media screen and (min-width: 768px) {
  .our-services-card .buttons {
    max-width: 90%;
  }
}
@media screen and (min-width: 1024px) {
  .our-services-card .buttons {
    max-width: 80%;
  }
}

.index-grid-card {
  overflow: hidden;
  position: relative;
}
.index-grid-card > * + * {
  margin-top: 40px;
}
@media screen and (min-width: 1024px) {
  .index-grid-card > * + * {
    margin-top: 75px;
  }
}
.index-grid-card:hover .card-image img {
  transform: scale(1.1);
}
.index-grid-card:hover .card-image svg {
  transform: translateX(0);
}
.index-grid-card:hover .card-image .layer {
  opacity: 1;
}
.index-grid-card .card-image {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  position: relative;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.index-grid-card .card-image img {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scale(1);
  overflow: hidden;
}
.index-grid-card .card-image img,
.index-grid-card .card-image svg {
  transition: transform 0.4s ease;
}
.index-grid-card .card-image svg {
  position: absolute;
  z-index: 1;
  transform: translateX(-600%);
  cursor: pointer;
}
.index-grid-card .card-image .layer {
  position: absolute;
  inset: 0;
  background: linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0) 100%);
  width: 100%;
  height: 100%;
}
.index-grid-card .card-content:not(.redactor-content) {
  color: var(--color-red);
}
.index-grid-card .card-content .redactor-content {
  color: var(--color-white);
}
.index-grid-card .card-content .redactor-content > * {
  max-width: 90%;
}
.index-grid-card .card-content a::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
}
.index-grid-card .card-content > * + * {
  margin-top: 15px;
}
.index-grid-card .card-content.blog-content {
  padding-left: 25px;
  border-left-width: 2px;
  border-left-color: var(--color-red);
  border-left-style: solid;
}

@media screen and (min-width: 1024px) {
  div.redactor-paragraph {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
div.redactor-paragraph .text-with-services-paragraph p.callout-text {
  font-size: var(--quote-mobile-font-size);
  font-style: var(--quote-font-style);
  font-weight: var(--quote-font-weight);
  font-family: var(--quote-font-family);
  line-height: var(--quote-mobile-line-height);
  display: inline-flex;
}
@media (min-width: 1024px) {
  div.redactor-paragraph .text-with-services-paragraph p.callout-text {
    font-size: var(--quote-desktop-font-size);
  }
}

div.full-single-image img {
  border-radius: 20px;
}

div.single-image-with-caption {
  position: relative;
  overflow: unset;
}
div.single-image-with-caption > figure {
  overflow: hidden;
}
div.single-image-with-caption::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
@media (min-width: 1024px) {
  div.single-image-with-caption::before {
    content: unset;
  }
}
div.single-image-with-caption.full-width figure img {
  border-radius: 0;
  max-width: none;
  width: 100%;
}
div.single-image-with-caption figure {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
@media (min-width: 1024px) {
  div.single-image-with-caption figure {
    position: relative;
  }
}
div.single-image-with-caption figure img {
  border-radius: 2rem;
  inset: 0;
  height: 100%;
  object-fit: cover;
  max-height: 612px;
}
@media (min-width: 1024px) {
  div.single-image-with-caption figure img {
    position: relative;
  }
}
div.single-image-with-caption figure .ratio-content {
  z-index: 2;
  align-items: end;
  height: 100%;
  width: 100%;
  --padding: 0 calc(var(--space-unit-1));
}
div.single-image-with-caption figure .ratio-content .banner-caption {
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 10px;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  div.single-image-with-caption figure .ratio-content .banner-caption {
    margin-bottom: 54px;
    margin-left: 32px;
  }
}

#dots-nav {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 235px;
}
#dots-nav .dot {
  width: 15px;
  height: 15px;
  opacity: 0.6;
  border-radius: 50%;
  margin-bottom: 15px;
  background-color: var(--color-white);
  cursor: pointer;
}
div.video-mobile-controls {
  position: absolute;
  width: 210px;
  height: 50px;
  margin: 0 auto;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.3s linear;
}
div.video-mobile-controls.active {
  opacity: 1;
}
div.video-mobile-controls button {
  width: 30px;
  height: 30px;
  color: black;
  cursor: pointer;
}
div.video-mobile-controls button svg {
  height: 100%;
  width: 100%;
}

html.services-entries-template {
  --root-background: var(--color-white);
}

body.services-entries-template header.main-header {
  --background: transparent;
}
body.services-entries-template header.main-header .navigation-button .icon path {
  stroke: var(--color-black) !important;
}
body.services-entries-template .service-banner .scroll-button {
  display: block;
  bottom: unset;
  top: -45px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5;
}

html.services-index-template {
  --root-background: var(--color-white);
}

body.services-index-template header.main-header {
  --background: transparent;
}
body.services-index-template header.main-header .navigation-button .icon path {
  stroke: var(--color-black) !important;
}
@media (min-width: 768px) {
  body.services-index-template section.call-to-action {
    position: relative;
  }
  body.services-index-template section.call-to-action .container {
    margin-top: -75px;
  }
}
@media (min-width: 1024px) {
  body.services-index-template section.call-to-action .container {
    margin-top: -125px;
  }
}

html.case-studies-index-template {
  --root-background: var(--color-white);
}

body.case-studies-index-template header.main-header {
  --background: transparent;
}
body.case-studies-index-template header.main-header .navigation-button .icon path {
  stroke: var(--color-black) !important;
}
@media (min-width: 1024px) {
  body.case-studies-index-template section.index-grid.services::after {
    --height: 250px;
  }
}
@media (min-width: 768px) {
  body.case-studies-index-template section.call-to-action {
    position: relative;
  }
  body.case-studies-index-template section.call-to-action .container {
    margin-top: -75px;
  }
}
@media (min-width: 1024px) {
  body.case-studies-index-template section.call-to-action .container {
    margin-top: -125px;
  }
}

html.case-study-entries-template {
  --root-background: var(--color-white);
}

body.case-study-entries-template header.main-header {
  --background: transparent;
}
body.case-study-entries-template header.main-header .navigation-button .icon path {
  stroke: var(--color-black) !important;
}
body.case-study-entries-template header.main-header:not(.header-sticky) {
  position: relative;
}
body.case-study-entries-template main {
  margin-top: -80px;
}
@media (min-width: 1024px) {
  body.case-study-entries-template main {
    margin-top: -100px;
  }
}
body.case-study-entries-template footer.main-footer {
  --background: var(--color-black);
}
body.case-study-entries-template footer.main-footer .container.bottom-links .social-accounts .social-accounts-list li a svg path {
  --fill: var(--color-white);
}
body.case-study-entries-template footer.main-footer .container.bottom-links .social-accounts .social-accounts-list li:hover a svg path {
  --fill: var(--color-red);
}
body.case-study-entries-template footer.main-footer .container.bottom-links .item a {
  --color: var(--color-white);
}
body.case-study-entries-template footer.main-footer .container a {
  --color: var(--color-white);
}

html.blog-index-template {
  --root-background: var(--color-white);
}

body.blog-index-template header.main-header {
  --background: transparent;
}
body.blog-index-template header.main-header .navigation-button .icon path {
  stroke: var(--color-black) !important;
}
@media (min-width: 768px) {
  body.blog-index-template section.call-to-action {
    position: relative;
  }
  body.blog-index-template section.call-to-action .container {
    margin-top: -75px;
  }
}
@media (min-width: 1024px) {
  body.blog-index-template section.call-to-action .container {
    margin-top: -125px;
  }
}
body.blog-index-template .index-grid .container:is(.grid-index-container) {
  --gap: var(--space-mobile-3) 20px !important;
}

body.blog-entries-template footer.main-footer {
  --background: var(--color-black);
}
body.blog-entries-template footer.main-footer .container.bottom-links .social-accounts .social-accounts-list li a svg path {
  --fill: var(--color-white);
}
body.blog-entries-template footer.main-footer .container.bottom-links .social-accounts .social-accounts-list li:hover a svg path {
  --fill: var(--color-red);
}
body.blog-entries-template footer.main-footer .container.bottom-links .item a {
  --color: var(--color-white);
}
body.blog-entries-template footer.main-footer .container a {
  --color: var(--color-white);
}

body.contact-template {
  background: var(--color-white);
}
body.contact-template header.main-header {
  --background: var(--color-black);
}
body.contact-template .hero-intro.contact {
  --background: var(--color-black);
}
body.contact-template .hero-intro.contact .container .subheading {
  --color: var(--color-white);
}
body.contact-template main section.hero-intro .wrapper {
  padding-bottom: var(--space-mobile-2);
}
@media (min-width: 1024px) {
  body.contact-template main section.hero-intro .wrapper {
    padding-bottom: var(--space-desktop-5);
  }
}
@media (min-width: 768px) {
  body.contact-template main section.call-to-action {
    position: relative;
  }
  body.contact-template main section.call-to-action .container {
    margin-top: -75px;
  }
}
@media (min-width: 1024px) {
  body.contact-template main section.call-to-action .container {
    margin-top: -125px;
  }
}

html.terms-and-conditions-template,
html.privacy-policy-template {
  --root-background: var(--color-white);
}

body.terms-and-conditions-template header.main-header,
body.privacy-policy-template header.main-header {
  --background: transparent;
}
body.terms-and-conditions-template header.main-header .navigation-button .icon path,
body.privacy-policy-template header.main-header .navigation-button .icon path {
  stroke: var(--color-black) !important;
}
body.terms-and-conditions-template .simple-paragraph .redactor-content,
body.privacy-policy-template .simple-paragraph .redactor-content {
  justify-content: start;
  max-width: 1140px;
}

body.packaging-template {
  background-color: var(--color-white);
}
body.packaging-template header.main-header {
  --background: transparent;
}
body.packaging-template header.main-header .navigation-button .icon path {
  stroke: var(--color-black) !important;
}
body.packaging-template section.call-to-action {
  background-color: var(--color-white);
  position: relative;
  z-index: 6;
}
body.packaging-template section.call-to-action .container {
  z-index: 7;
}
@media (min-width: 768px) {
  body.packaging-template section.call-to-action {
    position: relative;
  }
  body.packaging-template section.call-to-action .container .grid-cta {
    margin-top: -75px;
  }
}
@media (min-width: 1024px) {
  body.packaging-template section.call-to-action .container .grid-cta {
    margin-top: -150px;
  }
}
body.packaging-template .gsap-marker-scroller-end, body.packaging-template .gsap-marker-end {
  color: black !important;
  border-color: black !important;
}
body.packaging-template #main-footer {
  position: relative;
  z-index: 7;
}

body.default footer.main-footer {
  --background: var(--color-black);
}
body.default footer.main-footer .container.bottom-links .social-accounts .social-accounts-list li a svg path {
  --fill: var(--color-white);
}
body.default footer.main-footer .container.bottom-links .social-accounts .social-accounts-list li:hover a svg path {
  --fill: var(--color-red);
}
body.default footer.main-footer .container.bottom-links .item a {
  --color: var(--color-white);
}
body.default footer.main-footer .container a {
  --color: var(--color-white);
}