// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.main-header {
  --background: var(--color-secondary-background);
  background: var(--background);
  display: grid;
  grid-template-rows: 80px;
  align-items: center;
  z-index: 9;

  @media (min-width: $lg) {
    grid-template-rows: 100px;
  }

  .container {
    --layout-max-width: 1520px;
    @include container-grid(center);
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
  }


  .main-navigation {
    grid-column-start: -1;
  }

  .logo-link {
    svg {
      display: block;
    }
  }

  //colors for icon and logo changes
  #logo-nav-svg {
    width: 36px;
    height: 40px;

    path {
      fill: #ED1C24;
      transition: fill 0.2s linear;
    }

  }

  #navigation-button path {
    transition: stroke 0.2s linear;
  }

  &.icon {
    &__black {
      #navigation-button path {
        stroke: black !important;
      }
    }

    &__white {
      #navigation-button path {
        stroke: #fff !important;
      }
    }
  }

  &.logo {
    &__black {
      #logo-nav-svg path {
        fill: #000;
      }
    }

    &__white {
      #logo-nav-svg path {
        fill: #fff;
      }
    }
  }


}