div.team-member-card {
    padding-bottom: var(--space-mobile-2);
    &:nth-child(even) {
        @media screen and (max-width: 767px){
            display: flex;
            justify-content: flex-end;
            .team-member-card-wrapper {
                max-width: 70%;
                @media screen and (min-width: $md){
                    max-width: 31%;
                }
            }
        }
    }

    .team-member-card-wrapper {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: $lg){
            padding-bottom: 0;
        }
        .team-member-card-image {
            width: 169px;
            height: 169px;
            display: inline-block;

            @media screen and (min-width: $lg){
                max-width: 268px;
                width: 100%;
                height: auto;
            }
            .team-member-images-wrapper {
                width: 169px;
                height: 169px;
                margin-right:10px;
                position:relative;
                @media screen and (min-width: $lg){
                    width: 250px;
                    height: 250px;
                }

                .team-member-main-image, .team-member-secondary-image {
                    position:absolute;
                    width: 169px;
                    height: 169px;
                    backface-visibility: hidden;
                    -webkit-backface-visibility: hidden;
                    -moz-backface-visibility: hidden;
                    -ms-backface-visibility: hidden;
                    border-radius: 50%;
                    background-size: cover;
                    @media screen and (min-width: $lg){
                        width: 250px;
                        height: 250px;
                    }
                }
            }
        }
        .team-member-card-text {
            position: relative;
            padding-left: 1.5rem;
            max-width: 18rem;
            margin-top: var(--space-mobile-1);
            display: inline-block;
            @media screen and (min-width: $lg){
                max-width: none;
                margin-top: var(--space-desktop-1);
            }
            .h4 {
                color: var(--color-red);
                font-family: var(--h4-font-family);
                padding-bottom: 1rem;
            }
            .team-member-card-position {
                color: var(--color-gray);
                border-bottom: 1px solid var(--color-gray);
                width: max-content;
                margin-bottom: 1rem;
            }
            p {
                color: var(--color-white);
                // display: inline-block;
            }
            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 2px;
                background-color: var(--color-red);
                color: var(--color-red);
                top: 0;
                left: 0;
                z-index: 1;
            }
        }
    }
}