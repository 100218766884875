#dots-nav{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 235px;

    .dot{
        width: 15px;
        height: 15px;
        opacity: 0.6;
        border-radius: 50%;
        margin-bottom: 15px;
        background-color: var(--color-white);
        cursor: pointer;

        &.active{
            // opacity: 1;
            // height: 75px;
            // border-radius: 10px;
        }
    }
}