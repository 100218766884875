$cubicBezier: cubic-bezier(0.390, 0.575, 0.565, 1.000);

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}

.modal-lightbox {
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100vw;
  z-index: 11;
  background: rgb(0, 0, 0, 0.8);
  overflow: hidden;
  //animation: fade-in 200ms both;
  // opacity: 1;
  // visibility: visible;
  transition: opacity 300ms $cubicBezier, visibility 300ms $cubicBezier;


  .modal-lightbox-overlay {
    position: fixed;
    inset: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .modal-lightbox-container {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
  }

  .close-lightbox {
    &.focus-visible {
      outline: 3px solid var(--color-white);
      outline-offset: 2px;
    }
  }

  .modal-lightbox-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 300ms $cubicBezier;

    img {
      //max-height: 80vh;
      //max-width: 85vw;
      max-width: 85%;
      object-fit: cover;
      height: auto;
      border-radius: 2rem;
      position: relative;
      z-index: 3;

      @media (min-width: $md) {
        max-width: 85vw;
      }

      @media (min-width: $lg) {
        max-height: 80vh;
      }
    }


  }
}

modal-lightbox {
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
}

modal-lightbox[show="false"] {
  // opacity: 0;
  // visibility: hidden;

  .modal-lightbox {
    opacity: 0;
    visibility: hidden;
    transition-delay: 300ms;
  }

  .modal-lightbox-content {
    opacity: 0;
    transition-delay: 0s;
  }

  button.close-lightbox {
    opacity: 0;
    visibility: hidden;
    transition-delay: 300ms;
  }
}

modal-lightbox[show="true"] {
  z-index: 12;


  .modal-lightbox {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
  }

  .modal-lightbox-content {
    opacity: 1;
    transition-delay: 300ms !important;
  }

  button.close-lightbox {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}