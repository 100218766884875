// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {

    &,
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @content;
    }
  }

  @else {

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Kitty Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin flex-container-between {
  --container-display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin transition-300 {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@mixin grid {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  display: grid;
  grid-template-columns: var(--grid);
  align-items: center;
  gap: var(--gap);

  @media (min-width: $md) {
    --grid: var(--grid-md);
  }

  @media (min-width: $lg) {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }
}

@mixin container-grid($align) {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: var(--grid);
  align-items: $align;
  gap: var(--gap);

  @media (min-width: $md) {
    --grid: var(--grid-md);
  }

  @media (min-width: $lg) {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }

  .grid-item {
    grid-column: span 2;
  }

  .full-grid-item {
    grid-column: span 4;
  }
}

/**
 * Used to create a two columns dynamic grid system.
 *
 * num-columns: Number of columns we want to split the container in.
 * size-left: Number of columns the first div is gonna take.
 * offset-left: The column number the first div is going to start.
 * size-right: Number of columns the second div is gonna take.
 * offset-right: The column number the second div is going to start.
 * single-column: if the grid only has one column.
 */
@mixin container-columns($num-columns: 12, $size-left: 6, $offset-left: 0, $size-right: 6, $offset-right: 0, $single-column: false) {
  @media screen and (min-width: $lg) {
    display: grid;
    grid-template-columns: repeat($num-columns, 1fr);

    >div {
      &:first-child {
        grid-column: $offset-left / span $size-left;
      }

      @if $single-column ==false {
        &:last-child {
          grid-column: $offset-right / span $size-right;
        }
      }
    }
  }
}

/* A mixin that is used to create a section with a side. */
@mixin section-side($direction: 'left') {
  padding-left: calc(var(--space-unit-1));
  padding-right: calc(var(--space-unit-1));
  $padding: calc((100vw - $max-width) / 2);

  @media screen and (min-width: $lg) {
    @if $direction ==left {
      padding-right: $padding;
      padding-left: 0;
    }

    @else {
      padding-left: $padding;
      padding-right: 0;
    }
  }
}

/* This mixin is used to set the grid-column property of an element on mobile. */
@mixin grid-item-full-mobile {
  grid-column: span 4;
}

/* This mixin is used to set the grid-column property of an element on tablet.
start: The column number the element is going to start.
span: The number of columns the element is going to span. */
@mixin grid-item-set-tablet($start, $span) {
  @media (min-width: $md) {
    grid-column: $start / span $span;
  }
}

/* This mixin is used to set the grid-column property of an element on desktop.
start: The column number the element is going to start.
span: The number of columns the element is going to span. */
@mixin grid-item-set-desktop($start, $span) {
  @media (min-width: $lg) {
    grid-column: $start / span $span;
  }
}

/* This mixin is used to set the grid-column property of an element.
bp: The breakpoint at which the grid-column property is set.
start: The column number the element is going to start.
span: The number of columns the element is going to span.
*/
@mixin set-grid-item-columns($bp, $start, $span) {
  @media (min-width: $bp) {
    grid-column: $start / span $span;
  }
}

/* This mixin is used to set the grid-column property of an element.
bp: The breakpoint at which the grid-column property is set.
*/
@mixin set-full-grid-item($bp: false) {
  @if $bp ==false {
    grid-column: 1 /-1;
  }

  @else {
    @media (min-width: $bp) {
      grid-column: 1 /-1;
    }
  }
}

/* A mixin that is used to create a grid system. */
@mixin container-grid-fix($align, $columns) {
  --grid: var(--grid-sm);
  --gap: var(--gutter-mobile);
  --container-display: grid;
  grid-template-columns: $columns;
  align-items: $align;
  gap: var(--gap);

  @media (min-width: $md) {
    --grid: var(--grid-md);
  }

  @media (min-width: $lg) {
    --grid: var(--grid-lg);
    --gap: var(--gutter-desktop);
  }

  .grid-item {
    grid-column: span 2;
  }

  .full-grid-item {
    grid-column: span 4;
  }
}


/* A mixin that is used to create a hover effect on a link. */
@mixin hover-line {
  position: relative;
  text-decoration: none;

  &:hover,
  &.focus-visible {

    &::after {
      transform: translateX(0);
    }
  }


  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: var(--hover-line, var(--color-red));
    bottom: -3px;
    left: 0;
    transform: translateX(-101%);
    transition: transform .4s ease;
  }
}

/* Mixin for use inside redactor-content > a */
@mixin redactor-link {
  color: var(--color, var(--color-black));
  position: relative;
  text-decoration: none;

  &:hover,
  &.focus-visible {
    &::after {
      width: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background: var(--color-red);
    bottom: -3px;
    left: 0;
    transition: width .4s ease;
  }
}

/* Creating a mixin that is used to create a black hover effect on a link. */
@mixin hover-line-black {
  position: relative;
  text-decoration: none;

  &:hover,
  &.focus-visible {
    text-decoration: none;

    &::after {
      transform: translateX(0);
    }
  }


  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: var(--hover-line, var(--color-black));
    bottom: -3px;
    left: 0;
    transform: translateX(-101%);
    transition: transform .4s ease;
  }
}

/* A mixin that is used to create a ratio container.
paddingMobile: The padding-top value for mobile.
paddingDesktop: The padding-top value for desktop.
destroyDesktop: If true, the padding-top value for desktop will be unset.
*/
@mixin ratio-container($paddingMobile, $overflow: hidden, $paddingDesktop: false, $destroyDesktop: true) {
  position: relative;
  overflow: $overflow;

  @if $overflow =='unset' {
    &>figure {
      overflow: hidden;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: $paddingMobile;

    @if $destroyDesktop {
      @media (min-width: $lg) {
        content: unset;
      }
    }

    @else {
      @if $paddingDesktop !=false {
        @media (min-width: $lg) {
          padding-top: $paddingDesktop;
        }
      }
    }
  }

}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin fontsize($minSize, $maxSize) {
  $minvw: 300;
  $maxvw: 1420;
  $vwCoefficient: (1600 * ($maxSize - $minSize)) / ($maxvw - $minvw);
  $remCoefficient: $minSize - ($minvw * ($maxSize - $minSize)) / ($maxvw - $minvw);
  font-size: clamp($minSize, $vwCoefficient + $remCoefficient, $maxSize);
}

@mixin cropmark-position($right, $left, $top, $bottom, $margin, $height, $width) {
  right: $right;
  left: $left;
  top: $top;
  bottom: $bottom;
  // margin 1 means horizontal line
  margin: if($margin ==1, 0 auto, auto 0);
  height: $height;
  width: $width;
}