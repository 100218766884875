html.case-study-entries-template {
  --root-background: var(--color-white);
}

body.case-study-entries-template {
  header.main-header {
    --background: transparent;

    .navigation-button {
      .icon path {
        stroke: var(--color-black) !important;
      }
    }
  }

  header.main-header:not(.header-sticky) {
    position: relative;
  }

  main {
    margin-top: -80px;

    @media (min-width: $lg) {
      margin-top: -100px;
    }
  }

  footer.main-footer {
    --background: var(--color-black);

    .container {
      &.bottom-links {
        .social-accounts {
          .social-accounts-list {
            li {
              a svg path {
                --fill: var(--color-white);
              }

              &:hover {
                a svg path {
                  --fill: var(--color-red);
                }
              }
            }
          }
        }

        .item {
          a {
            --color: var(--color-white);
          }

        }
      }

      a {
        --color: var(--color-white);
      }
    }
  }
}