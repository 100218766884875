section.case-study-hero {
  max-height: calc(90vh - 4.5rem);
  height: 90vh;
  overflow: hidden;
  position: relative;

  @media (min-width: $lg) {
    min-height: 800px;
  }

  .container {
    --container-display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: unset;
    padding: 0;
    position: relative;
    z-index: 1;
    height: 100%;

    .scroll-button-container {
      margin: 0 auto;
      position: relative;
      width: 100%;
      height: 80px;
      max-width: var(--layout-max-width);
    }


    .case-study-hero-text {
      width: 100%;
      height: 4.5rem;
      color: var(--color-white);
      font-family: var(--h4-font-family);
      font-size: 14px;
      display: flex;
      align-items: center;
      background-color: var(--color-red);

      @media screen and (min-width: $xxxl) {
        padding: 0;
      }

      &>div {
        max-width: var(--layout-max-width);
        width: 100%;
        margin: 0 auto;
        padding-left: calc(var(--space-unit-1));
        padding-right: calc(var(--space-unit-1));
      }
    }

  }

  img {
    //max-height: calc(90vh - 4.5rem);
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    inset: 0;
  }
}