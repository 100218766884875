.call-to-action {

  .container {
    padding: 0 0 var(--space-mobile-2) 0;
    @include container-grid(center);
    position: relative;
    z-index: 1;

    @media (min-width: $md) {
      padding-left: calc(var(--space-unit-1));
      padding-right: calc(var(--space-unit-1));
    }

    @media (min-width: $lg) {
      padding-bottom: var(--space-desktop-4);
    }


    .grid-cta {
      @include set-full-grid-item;
      padding: var(--space-mobile-2) calc(var(--space-unit-1));
      clip-path: inset(0 0 0 0);
      transition: clip-path .3s ease-out;
      background-color: var(--color-red);


      @media (min-width: $lg) {
        padding: var(--space-desktop-3) calc(var(--space-unit-1));
        grid-column: 2/span 10;

        &:hover {
          clip-path: inset(7% 5% 7% 5%);
        }

        &:has(.button.focus-visible) {
          clip-path: inset(7% 5% 7% 5%);
        }
      }

      text-align: center;

      h2+.redactor-content {
        margin-top: 20px;
      }

      .redactor-content+.button {
        margin-top: 40px;
      }

      .redactor-content {
        color: var(--color-white);
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}