html.terms-and-conditions-template,
html.privacy-policy-template {
  --root-background: var(--color-white);
}

body.terms-and-conditions-template,
body.privacy-policy-template {
  header.main-header {
    --background: transparent;

    .navigation-button {
      .icon path {
        stroke: var(--color-black) !important;
      }
    }
  }

  .simple-paragraph {
    .redactor-content {
      justify-content: start;
      max-width: 1140px;
    }


  }
}