.hero {
  background-color: var(--color-black);
  //height: calc(100vh - 100px);
  position: relative;
  display: grid;
  align-items: center;

  >video {
    position: absolute;
    height: 100vh;
    width: 100vw;
    inset: 0;
    object-fit: cover;
    display: none;
    z-index: 5;
    display: none;

    &.active {
      display: block;
    }
  }

  .h1 {
    --color-primary-text: var(--color-red);
  }

  .container {
    @include container-grid(center);
    grid-template-rows: 340px 370px;
    position: relative;
    //padding: 0;

    @media (max-width:1023px) {
      padding: 0;
    }

    @media (min-width: $lg) {
      padding-top: calc(var(--space-unit-1) * 3);
      padding-bottom: calc(var(--space-unit-1) * 3);
      grid-template-rows: 720px;
    }

    .grid-item-heading,
    .grid-item-video {
      grid-column: 1/-1;
    }

    .grid-item-video {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;

      &:hover {
        button {
          opacity: 1;
        }
      }

      img,
      video {
        position: absolute;
        height: 100%;
        width: 100%;
        inset: 0;
        object-fit: cover;

        &.mobile {

          @media (min-width: $lg) {
            display: none;
          }
        }
      }

      img {
        @media (max-width: $lg) {
          display: none;
        }
      }


      // @media (min-width: $md) {
      //   grid-column: -1/span 6;

      // }

      @media (min-width: $lg) {
        display: block;
        grid-column-start: 6;
        grid-column-end: 13;

        img {
          border-radius: 20px;
        }

        .button {
          display: none;
        }
      }

      .play-button{
        z-index: 2;
      }
    }

    .grid-item-heading {
      padding-left: calc(var(--space-unit-1));
      padding-right: calc(var(--space-unit-1));

      // @media (min-width: $md) {
      //   grid-column: 1/span 6;

      // }

      @media (min-width: $lg) {
        grid-column: 1/span 8;
        position: absolute;
        z-index: 1;
      }

      .h1 {
        max-width: 670px;

        @media (min-width: $lg) {
          max-width: unset;

        }

        @media (min-width: $lg) {
          transform: translateY(-50px);
        }

        &+button {
          display: none;

          @media (min-width: $lg) {
            display: inline-block;
          }
        }
      }
    }

  }
}