.header-sticky {
  position: sticky;
  top: 0;
  --color-secondary-background: transparent !important;
}

.header-hidden {
  transform: translateY(-100%);
}

.header-animate {
  transition: transform 0.15s ease-out, background 0.15s ease-out;
}