body.packaging-template {

  background-color: var(--color-white);
  // overflow: hidden;

  header.main-header {
    --background: transparent;

    .navigation-button {
      .icon path {
        stroke: var(--color-black) !important;
      }
    }
  }

  section.call-to-action {
    background-color: var(--color-white);
    position: relative;
    z-index: 6;

    .container {
      z-index: 7;
    }

    @media (min-width: $md) {
      position: relative;

      .container .grid-cta{
        margin-top: -75px;
      }
    }

    @media (min-width: $lg) {
      .container .grid-cta{
        margin-top: -150px;
      }
    }
  }

  .gsap-marker-scroller-end, .gsap-marker-end{
    color: black !important;
    border-color: black !important;
  }

  #main-footer{
    position: relative;
    z-index: 7;
  }

}