// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.main-footer {
  --background: var(--color-white);
  background: var(--background);
  position: relative;
  padding-bottom: 40px;
  padding-top: 80px;
  overflow: hidden;

  @media (min-width: $md) {
    padding-bottom: 100px;
    padding-top: 140px;
  }

  .cropmarks {
    width: 60%;
    height: calc(100% - 50px);
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: none;

    @media (min-width: $md) {
      width: 70%;
      height: calc(100% - 100px);
      display: block;
    }

    @media (min-width: $lg) {
      width: 80%;
    }

    @media (min-width: 1300px) {
      width: 85%;
    }

    .top {
      transform: translateY(0);
    }
  }

  .container {
    --gutter-mobile: 30px 16px;
    @include container-grid(start);

    &.bottom-links {
      padding-bottom: var(--space-mobile-2);
      position: relative;

      @media (min-width: $lg) {
        align-items: center;
      }

      .item {
        overflow: hidden;

        &:first-of-type {
          grid-column: 1/-1;
          order: 2;

          @media (min-width: $lg) {
            grid-column: 1/span 2;
          }
        }

        &:nth-child(2) {
          order: 3;
          grid-column: 1/-1;

          @media (min-width: $lg) {
            grid-column: 3/span 2;
          }
        }

        &:nth-child(3) {
          grid-column: 1/-1;
          color: var(--color-gray);
          order: 4;

          @media (min-width: $lg) {
            grid-row: 2;
            grid-column: 1/span 2;
          }
        }

        &:nth-child(4) {
          grid-column: 1/-1;
          color: var(--color-gray);
          order: 5;
          color: var(--color-gray);

          @media (min-width: $lg) {
            grid-row: 2;
            grid-column: 3/span 3;
          }

          a {
            color: var(--color-gray) !important;
            @include redactor-link;

          }
        }

        @media (min-width: $lg) {
          order: unset !important;
        }
      }

      a {
        --color: var(--color-black);
        color: var(--color);
        @include redactor-link;
      }

      .social-accounts {
        grid-column: 1/span 2;
        order: 1;

        @media (min-width: $lg) {
          grid-column: 7/span 3;
          order: unset;
        }

        .social-accounts-list {
          margin: 0;
          padding: 0;

          // li a svg path {
          //   --fill: var(--color-black);
          // }

          li {
            --background: var(--color-red);
          }

          li {
            a {
              position: relative;
              overflow: hidden;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .grid-item {
    .footer-title {
      margin-bottom: 20px;
      color: var(--color-tertiary-text);
    }


    ul {
      padding: 0;
      margin: 0;

      li+li {
        margin-top: 10px;
      }

      li {
        overflow: hidden;

        a {
          --color: inherit;
          color: var(--color);
          @include hover-line;
        }
      }
    }


    &.logo-footer {
      grid-column: span 4;

      @media (min-width: $md) {
        grid-column: span 2;
      }
    }

    @media (min-width: $md) {
      &.address-block {
        grid-row: 2;

        &:first-of-type {
          grid-column: 1/span 2;
        }

        &:last-of-type {
          grid-column: 3/span 2;
        }
      }
    }

    @media (min-width: $lg) {
      &.footer-block {
        &[data-footer-nav='1'] {
          grid-column: 7/span 2;
        }

        &[data-footer-nav='2'] {
          grid-column: 9/span 2;
        }

        &[data-footer-nav='3'] {
          grid-column: 11/span 2;
        }
      }
    }
  }
}