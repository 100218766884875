html.services-entries-template {
  --root-background: var(--color-white);
}

body.services-entries-template {
  header.main-header {
    --background: transparent;

    .navigation-button {
      .icon path {
        stroke: var(--color-black) !important;
      }
    }
  }

  .service-banner {
    .scroll-button {
      display: block;
      bottom: unset;
      top: -45px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 5;
    }
  }
}