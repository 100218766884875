div.redactor-paragraph {
    @media screen and (min-width: $lg){
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .text-with-services-paragraph {
        p {
            &.callout-text {
                font-size: var(--quote-mobile-font-size);
                font-style: var(--quote-font-style);
                font-weight: var(--quote-font-weight);
                font-family: var(--quote-font-family);
                line-height: var(--quote-mobile-line-height);
                display: inline-flex;
                
                @media (min-width: $lg) {
                    font-size: var(--quote-desktop-font-size);
                }
            }
        }
    }
}