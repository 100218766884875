.service:not(.card-link),
.featured-entry {
  display: inline-block;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  width: 100%;

  img {
    width: 100%;
    transition: transform .4s ease;
  }

  &:hover,
  &.hover {
    img {
      transform: scale(1.1);
    }

    .badge {
      transform: translateY(0);
      opacity: 1;
    }

    svg {
      transform: translateX(0);
    }
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    //background: linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0.0) 100%);
    width: 100%;
    height: 100%;
  }

  .layer {
    position: absolute;
    inset: 0;
    background: linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0.0) 100%);
    width: 100%;
    height: 100%;
  }

  .badge {
    position: absolute;
    bottom: 30px;
    left: 30px;
    display: inline-block;
    color: var(--color-white);
    background: var(--color-black);
    z-index: 1;
    padding: 8px 40px;
    border-radius: 20px;
  }

  .badge,
  svg {
    transition-property: transform;
    transition-duration: .4s;
    transition-timing-function: ease;
  }

  .badge {
    transform: translateY(300%);
  }

  svg {
    transform: translateX(-600%);
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 1;
  }

}