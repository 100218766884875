section.packaging-pannels .panel{

    &.four {
        z-index: 4;
        background-color: var(--color-red);
        color: var(--color-white);


        .package-img-container {
            width: 580px;
            top: 400px;

            @media (max-width: $md){
                top: 250px;
                width: 300px;
            }

            img {
                &.one{
                    width: 250px;
                    left: 0;
                    right: unset;
                    z-index: 2;
                }

                &.two{
                    width: 300px;
                    top: -50px;
                    right: 0;
                    left: unset;
                }

                @media (max-width: $md){
                    &.one{
                        width: 120px;
                    }
    
                    &.two{
                        width: 140px;
                        top: -20px;
                    }
                }
            }

        }
    }
}