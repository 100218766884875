section.packaging-pannels .panel{

    &.two {
        z-index: 1;
        background-color: var(--color-black);
        color: var(--color-white);
       

        .package-img-container {

            top: 280px;

            @media (max-width: $md){
                top: 250px;
            }

            img {
                width: 400px;
                
                &:not(.hidden-js){
                    opacity: .6;
                }

                @media (max-width: $md){
                    width: 200px;
                }


            }
        }
    }

}