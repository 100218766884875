menu-drawer {
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity .3s ease;

  &:focus {
    outline: none;
  }

  .menu-drawer {
    height: 100%;
    width: 100%;

    .container {
      --layout-max-width: 1520px;
      @include container-grid(center);
      grid-template-rows: 100px 1fr;
      padding-top: 0;
      padding-bottom: 0;

      .logo-drawer {
        opacity: 0;
      }

      .button {
        opacity: 0;
        grid-column-start: 4;
        justify-self: end;

        @media (min-width: $md) {
          grid-column-start: 8;
        }

        @media (min-width: $lg) {
          grid-column-start: 12;
        }
      }


      .navigation-container {
        grid-column: 1/span 4;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1.5em;


        @media (min-width: $md) {
          grid-column: 1/span 8;
          grid-template-columns: 1fr 35%;
        }

        @media (min-width: $lg) {
          min-height: 80vh;
          grid-column: 1/span 12;
          grid-template-columns: 1fr 45%;
          align-items: center;
        }

        .info-blocks {
          margin-top: 20px;
          margin-bottom: 50px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-self: center;
          min-height: 70%;
          row-gap: 1.5em;
          column-gap: .7em;

          @media (min-width: $md) {
            column-gap: 0;
            margin-bottom: unset;
          }

          @media (min-width: $lg) {
            align-items: center;
          }

          .drawer-navigation-blocks {
            color: var(--color-black);

            a {
              color: inherit;
            }

            h3 {
              margin-bottom: 10px;
              color: var(--color-white);
            }
          }

          .address-block {
            a {
              --hover-line: var(--color-black);
            }

            @media (max-width: 374px) {
              grid-column: 1/span 4 !important;
            }

            &:first-of-type {
              grid-column: 1;

              @media (min-width: $md) and (max-width: 1023px) {
                grid-column: 1/span 4;
              }
            }

            &+.address-block {
              grid-column: 2;

              @media (min-width: $md) and (max-width: 1023px) {
                grid-column: 1/span 4;
              }
            }
          }

          .contact-block {
            grid-column: 1/span 4;
          }

          .social-accounts {
            grid-column: 1/span 4;
          }

          .drawer-navigation-blocks,
          .social-accounts {
            opacity: 0;
          }


        }

        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          @media (min-width: $lg) {
            height: 80%;
          }
        }

        ul li a,
        ul li span {
          color: var(--color-white);
          text-decoration: none;
          letter-spacing: 0.1em;
        }

        .nav-items {
          li {
            //overflow: hidden;
            display: block;
          }

          li .nav-item {
            display: inline-flex;
            align-items: start;
            will-change: transform;
            column-gap: 15px;

            .hover,
            .current {
              --translatey: 18px;
              display: inline-block;
              height: 2px;
              width: 15px;
              background: var(--color-white);
              transform: translateY(var(--translatey));

              @media (min-width: $lg) {
                --translatey: 28px;
              }
            }

            .hover {
              --translatexy: -30px, 18px;

              @media (min-width: $lg) {
                --translatexy: -30px, 28px;
              }

              transform: translate(--translatexy);
              opacity: 0;
              transition: opacity .2s ease,
              transform .2s ease;
            }

            .text {
              transition: transform .2s ease;
              display: inline-block;
            }

            // &.focus-visible {
            //   .hover {
            //     //transform: translate(0, 28px);
            //     --translatexy: 0, 18px;

            //     @media (min-width: $lg) {
            //       --translatexy: 0, 28px;
            //     }

            //     opacity: 1;
            //   }

            //   .text {
            //     transform: translateX(0);
            //   }
            // }

            &:is(a) {

              &:hover,
              &.focus-visible {
                .hover {
                  opacity: 1;
                }

                .hover+.text {
                  transform: translateX(0);
                }

              }

              .current+.text {
                transform: translateX(0);
              }

              .hover+.text {
                transform: translateX(-30px);
              }
            }
          }
        }

        .social-accounts-list {
          li::after {
            transform: translateY(10px);
          }

          li {
            a svg path {
              --fill: var(--color-white);
            }

            &:hover {
              &::after {
                transform: translateY(-40px);
              }

              a svg path {
                --fill: var(--color-black);
              }
            }
          }
        }
      }
    }
  }


  &[show="true"] {
    visibility: visible;
    opacity: 1;
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;
  }
}