.social-accounts {
  ul.social-accounts-list {
    display: flex;
    flex-direction: row !important;
    height: auto;
    align-items: center;
    gap: 1.5rem;
    justify-content: start !important;

    li {
      position: relative;
      --background: var(--color-black);

      &:hover {
        &::after {
          transform: translateY(-10px);
        }

        a svg path {
          --fill: var(--color-red);
        }
      }

      &::after {
        content: '';
        position: absolute;
        //bottom: 0;
        height: 2px;
        width: 100%;
        left: 0;
        transform: translateY(40px);
        background: var(--background);
        transition-property: transform;
        transition-duration: .3s;
        transition-timing-function: ease;
      }

      a {
        height: 31px;
        width: 31px;

        &::after {
          content: unset !important;
        }

        svg {
          height: 100%;
          width: 100%;
          display: block;

          path {
            fill: var(--fill, var(--color-black));
            transition: fill .3s ease;
          }
        }
      }
    }
  }

  ul li a {
    opacity: 1 !important;
  }
}