/**
 * Basic typography style for copy text
 */

html:not(.no-js) {

  .h1,
  .button,
  .subtitle,
  .social-links *,
  .logo-heading {
    opacity: 0;
    transition: opacity .3s ease;
  }

  &.fonts-loaded.motion-reduce {

    .h1,
    .button,
    .subtitle,
    .social-links *,
    .logo-heading {
      opacity: 1 !important;
    }
  }
}

.panel-header{
  // --fz: var(--panel-mobile-font-size);
  // --lh: var(--panel-mobile-line-height);
  font-family: var(--panel-font-family);
  line-height: 1;
  font-weight: normal;
  font-size: var(--panel-mobile-font-size);

  @media (min-width: $md) {
    font-size: var(--panel-tablet-font-size);
  }

  @media (min-width: $lg) {
    font-size: var(--panel-desktop-font-size);
  }
}

.h1 {
  --fz: var(--h1-mobile-font-size);
  --lh: var(--h1-mobile-line-height);
  font-family: var(--h1-font-family);
  line-height: var(--lh);
  font-weight: normal;
  color: var(--color-primary-text);
  font-size: clamp(var(--h1-mobile-font-size), var(--h1-coefficient), var(--h1-desktop-font-size));

  @media (min-width: 1280px) {
    --lh: var(--h1-desktop-line-height);
  }
}

.h2 {
  --fz: var(--h2-mobile-font-size);
  --lh: var(--h2-mobile-line-height);
  font-family: var(--h2-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--h2-mobile-font-size), var(--h2-coefficient), var(--h2-desktop-font-size));
  letter-spacing: 0.03em;
  font-weight: normal;

  @media (min-width: $lg) {
    //--fz: var(--h2-desktop-font-size);
    --lh: var(--h2-desktop-line-height);
  }

  &.smaller{
    line-height: 1;
    font-size: clamp(25px, var(--h3-coefficient), 40px);
  }
}

.h3 {
  --fz: var(--h3-mobile-font-size);
  --lh: var(--h3-mobile-line-height);
  font-family: var(--h3-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--h3-mobile-font-size), var(--h3-coefficient), var(--h3-desktop-font-size));
  letter-spacing: 2px;
  font-weight: normal;

  @media (min-width: $lg) {
    //--fz: var(--h3-desktop-font-size);
    --lh: var(--h3-desktop-line-height);
  }
}

.h4 {
  --fz: var(--h4-mobile-font-size);
  --lh: var(--h4-mobile-line-height);
  font-family: var(--h4-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--h4-mobile-font-size), var(--h4-coefficient), var(--h4-desktop-font-size));
  font-weight: normal;

  @media (min-width: $lg) {
    //--fz: var(--h4-desktop-font-size);
    --lh: var(--h4-desktop-line-height);
  }
}

.subtitle {
  --fz: var(--subtitle-mobile-font-size);
  --lh: var(--subtitle-mobile-line-height);
  font-family: var(--subtitle-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--subtitle-mobile-font-size), var(--subtitle-coefficient), var(--subtitle-desktop-font-size));
  font-weight: normal;

  @media (min-width: $lg) {
    //--fz: var(--subtitle-desktop-font-size);
    --lh: var(--subtitle-desktop-line-height);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  &>a {
    text-decoration: none;
    color: inherit;
  }
}

.quote,
blockquote {
  --fz: var(--quote-mobile-font-size);
  --lh: var(--quote-mobile-line-height);
  font-family: var(--quote-font-family);
  line-height: var(--lh);
  font-size: clamp(var(--quote-mobile-font-size), var(--quote-coefficient), var(--quote-desktop-font-size));
  font-style: italic;
  font-weight: normal;

  @media (min-width: $lg) {
    //--fz: var(--quote-desktop-font-size);
    --lh: var(--quote-desktop-line-height);
  }
}

.button-text,
.text-button-text,
.button {
  font-family: var(--button-text-font-family);
  line-height: var(--button-text-line-height);
  font-size: var(--button-text-font-size);
  font-weight: normal;
}

.link-text,
.text-link-text {
  font-family: var(--link-text-font-family);
  line-height: var(--link-text-line-height);
  font-size: var(--link-text-font-size);
  font-weight: normal;
}

p {
  a {
    color: inherit;
    text-decoration-thickness: 2px;
  }
}