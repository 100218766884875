// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

html:not(.motion-reduce) {

  .overlay {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: var(--color-red);
    will-change: transform;
  }
}

html:is(.motion-reduce) {

  overlay-layer .overlay {
    display: none;
  }
}



/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: $max-width;
  /* 1 */
  margin-left: auto;
  /* 2 */
  margin-right: auto;
  /* 2 */
  // padding-left: var(--space-unit-1);
  // /* 3 */
  // padding-right: var(--space-unit-1);
  /* 3 */
  padding: calc(var(--space-unit-1));
  width: 100%;
  /* 1 */
  display: var(--container-display, block);
}

.ratio-content {
  --padding: calc(var(--space-unit-1));
  max-width: $max-width;
  padding: var(--padding);
  width: 100%;
  position: absolute;
  inset: 0;
  margin: auto;
  display: flex;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.uppercase,
.upper {
  text-transform: uppercase;
}

.lowercase,
.lower {
  text-transform: lowercase;
}

.second-row {
  grid-row: 2;
}

.full-grid-item {
  grid-column: 1/-1 !important;
}

.text-center {
  text-align: center;
}

.image-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.redactor-content {
  p+p {
    margin-top: 20px;
    
  }

  ul {
    li {
      list-style: square;
    }
  }

  p, li {
    a {
      @include redactor-link;
    }
  }
}

.fake {
  position: absolute;
  inset: 0;
  height: 25%;
  width: 100%;
  background-color: var(--fake-background);

  @media (min-width: 550px) {
    height: 50%;
  }

  &.fake-content {
    @media (min-width: 550px) {
      height: 26%;
    }
  }
}

.hidden {
  display: none;
}


.grecaptcha-badge {
  bottom: 100px !important;
  z-index: 2;

  @media screen and (min-width: $lg) {
      bottom: 86px !important;
  }
}

figure iframe{
  width: 100%;
  max-width: 640px;
}