html.case-studies-index-template {
  --root-background: var(--color-white);
}

body.case-studies-index-template {
  header.main-header {
    --background: transparent;

    .navigation-button {
      .icon path {
        stroke: var(--color-black) !important;
      }
    }
  }

  section.index-grid.services {
    &::after {
      @media (min-width: 1024px) {
        --height: 250px;
      }
    }
  }

  section.call-to-action {
    @media (min-width: $md) {
      position: relative;

      .container {
        margin-top: -75px;
      }
    }

    @media (min-width: $lg) {
      .container {
        margin-top: -125px;
      }
    }
  }
}