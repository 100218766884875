.blog-post-hero {
    background-color: var(--color-white);
    padding-bottom: 0;
    @media (min-width: $lg) {
        padding-bottom: 8rem;
    }
    .wrapper {
        background-color: var(--color-black);
        padding-top: var(--space-mobile-2);
        padding-bottom: var(--space-mobile-3);
        padding-left: calc(var(--space-unit-1));
        padding-right: calc(var(--space-unit-1));

        @media (min-width: $lg) {
            padding-top: var(--space-desktop-4);
            padding-bottom: 0 ;
        }
    }

    .typewriter-js {
        span {
            color: var(--color-white);
        }
    }

    .container {
        padding: 0;
        position: relative;

        h1 {
            --color: var(--color-red);
            color: var(--color);
            text-align: center;
            margin: 25px auto 15px;
        }

        .grid-item-text {
            .content {
                max-width: 900px;
                margin-left: auto;
                margin-right: auto;

                p {
                    color: var(--color-white);
                }
            }
        }

        .blog-post-image {
            display: flex;
            justify-content: center;

            img {
                
                transform: translateY(10rem);
                border-radius: 2rem;
                
                @media (min-width: $lg) {
                    max-width: 750px;
                    height: auto;
                }
            }
        }
    }
}