$index-card-transition-default: transform .4s ease;

.index-grid-card {
  overflow: hidden;
  position: relative;



  &>*+* {
    margin-top: 40px;

    @media screen and (min-width: $lg) {
      margin-top: 75px;
    }
  }

  &:hover {
    .card-image {
      img {
        transform: scale(1.1);
      }

      svg {
        transform: translateX(0);
      }

      .layer {
        opacity: 1;
      }
    }
  }




  .card-image {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    img {
      border-radius: 20px;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: scale(1);
      overflow: hidden;
    }

    img,
    svg {
      transition: $index-card-transition-default;
    }

    svg {
      position: absolute;
      z-index: 1;
      transform: translateX(-600%);
      cursor: pointer;
    }

    .layer {
      position: absolute;
      inset: 0;
      background: linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0.0) 100%);
      width: 100%;
      height: 100%;
    }
  }

  .card-content {
    &:not(.redactor-content) {
      color: var(--color-red);
    }

    .redactor-content {
      color: var(--color-white);

      &>* {
        max-width: 90%;
      }
    }

    a::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }

    &>*+* {
      margin-top: 15px;
    }

    &.blog-content {
      padding-left: 25px;
      border-left-width: 2px;
      border-left-color: var(--color-red);
      border-left-style: solid;
    }

    // a:focus {
    //   outline: none;
    // }
  }

}