.marquee-component {
  --color-primary-background: var(--color-red);
  background-color: var(--color-primary-background);
  max-width: 100%;
  overflow: hidden;

  .dot {
    display: inline-block;
    height: 6px;
    width: 6px;
    background-color: var(--color-black);
    margin: 0 2rem;
  }


  .marquee-element {
    display: inline-block;
    padding: 25px 0;

    // &+.marquee-element {
    //   margin: 0 2rem;
    // }

    // &::before {
    //   content: '';
    //   display: inline-block;
    //   height: 6px;
    //   width: 6px;
    //   background-color: var(--color-black);
    //   margin: 0 1rem;
    // }
  }

  &>* {
    --color-primary-text: var(--color-white);
    color: var(--color-primary-text);
  }
}