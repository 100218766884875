div.single-image-with-caption {
    @include ratio-container(100%, unset);
    &.full-width {
        figure {
            img {
                border-radius: 0;
                max-width: none;
                width: 100%;
            }
        }
    }
    figure {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        @media (min-width: $lg) {
            position: relative;
        }
        img {
            border-radius: 2rem;
            inset: 0;
            height: 100%;
            object-fit: cover;
            max-height: 612px;

            @media (min-width: $lg) {
                position: relative;
            }
        }
        .ratio-content {
            z-index: 2;
            align-items: end;
            height: 100%;
            width: 100%;
            --padding: 0 calc(var(--space-unit-1));
        
            .banner-caption {
                border-radius: 10px;
                background-color: var(--color-white);
                padding: 10px;
                margin-bottom: 20px;

                @media (min-width: $lg) {
                    margin-bottom: 54px;
                    margin-left: 32px;
                }
            }
        }
    }
}