section.case-study-banner {
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-3);

  @media (min-width: $lg) {
    padding-top: 0;
    padding-bottom: var(--space-desktop-8);
  }

  &.blog-entries-item {
    margin-top: 0;
    padding-top: 0;
    background-color: var(--color-white);
    padding-bottom: var(--space-mobile-3);

    @media (min-width: $lg) {
      padding-bottom: var(--space-desktop-8);
    }

    &>div {
      padding-top: var(--space-mobile-2);

      @media (min-width: $lg) {
        padding-top: 0;
      }
    }
  }
}