html.services-index-template {
  --root-background: var(--color-white);
}

body.services-index-template {
  header.main-header {
    --background: transparent;

    .navigation-button {
      .icon path {
        stroke: var(--color-black) !important;
      }
    }
  }

  section.call-to-action {
    @media (min-width: $md) {
      position: relative;

      .container {
        margin-top: -75px;
      }
    }

    @media (min-width: $lg) {
      .container {
        margin-top: -125px;
      }
    }
  }
}