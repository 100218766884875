section.images-grid {
  background-color: var(--color-secondary-background);
  position: relative;

  .container:not(.title-container, .offset-left-title) {
    @include container-grid(center);
    align-items: inherit;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    z-index: 1;

    @media (max-width: $sm) {
      gap: 0;
    }

    @media (min-width: $lg) {
      padding-bottom: var(--space-desktop-7);
      gap: var(--gap);
    }

    .title-grid-item {
      color: var(--color-dark);
      @include set-full-grid-item;
      @include set-grid-item-columns($lg, 1, 1);
      margin-bottom: 60px;

      @media (min-width: $lg) {
        margin-bottom: unset;
      }

      span {
        @media (min-width: $lg) {
          display: block;
          transform: rotate(180deg);
          writing-mode: vertical-lr;
          transform-origin: center;
        }
      }
    }

    .grid-item {
      @include grid-item-full-mobile;

      @media (min-width: 550px) {
        grid-column: span 2;
      }

      @media (min-width: $md) {
        grid-column: span 4;
      }

      @media (min-width: $lg) {
        grid-column: span 5;
      }
    }
  }
}