.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  visibility: hidden;
  transition: transform .3s ease, visibility .3s ease;
  z-index: 5;

  &.active {
    visibility: visible;
    transform: translateY(0);
  }

  &>div {
    --background: var(--color-red);
    background: var(--background);
  }

  .container {
    position: relative;
  }

  .cookies-content {
    display: grid;
    gap: 1.5rem;

    @media (min-width: $sm) {
      grid-template-columns: 1fr auto;
      align-items: center;
    }



    // p {
    //   flex: 1 0 30%;
    // }

    // div {
    //   flex: 1;
    // }

    &:not(button) {
      color: var(--color-white);
    }

    .close-cookies {
      right: 10px;
      top: 10px;
    }
  }

}