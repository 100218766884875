.hero-intro {
  --background: var(--color-white);
  background-color: var(--background);
  overflow: hidden;

  .wrapper {
    padding-top: var(--space-mobile-2);
    padding-bottom: var(--space-mobile-5);
    padding-left: calc(var(--space-unit-1));
    padding-right: calc(var(--space-unit-1));

    @media (min-width: $lg) {
      padding-top: var(--space-desktop-4);
    }
  }

  .container {
    padding: 0;
    position: relative;
    @include container-grid(start);

    .subheading {
      --color: inherit;
      color: var(--color);
    }

    h1 {
      --color: var(--color-red);
      color: var(--color);
      text-align: center;
      margin: 25px auto 15px;
    }

    .grid-item-text {
      .content {
        --color: inherit;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        color: var(--color);
      }
    }
  }

}