.our-process {
  background: var(--color-white);
  padding-top: var(--space-mobile-2);
  padding-bottom: var(--space-mobile-2);
  // overflow: hidden;
  position: relative;

  @media (min-width: $md) {
    padding-bottom: var(--space-mobile-5);
  }
  

  @media (min-width: $lg) {
    padding-top: var(--space-desktop-8);
    padding-bottom: var(--space-desktop-8);
  }

  >div{
    position: relative;
    >video {
      position: absolute;
      height: 100vh;
      width: 100vw;
      inset: 0;
      object-fit: cover;
      display: none;
      z-index: 10;
  
      &.active {
        display: block;
      }
    }
  }

  .crop-mark {
    position: absolute;
    right: 35px;
    top: 85px;
  }

  .video-heading {
    color: var(--color-red);
  }

  .container {
    @include container-grid(start);

    &.heading-container {
      padding-bottom: 0;
      margin-bottom: -50px;
      position: relative;
      z-index: 1;

      &>* {
        grid-column: 1/-1;
      }

      @media (min-width: $md) {
        z-index: 2;
        left: 50px;
        margin-bottom: -135px;
      }

      @media (min-width: $lg) {
        margin-bottom: -165px;
      }

      @media (min-width: 1470px) {
        margin-bottom: -195px;
      }

      .cropmarks{
        width: 0px;
        height: 0px;
        left: unset;
        top: 0;
        right: 30px;
        
        @media (min-width: $sm) {
          right: 100px;
        }

        >div{
          &.left{
            left: 0;
            transform: translate(calc(-100% + 2px), calc(-100% + 2px));
            &:before{
              height: 60%;
            }
            &:after{
              width: 60%;
            }
          }
          &.right{
            right: 0;
            transform: translate(100%, 100%);

            &:before{
              width: 60%;
            }
            &:after{
              height: 60%;
            }
          }
        }
      }

    }

    &.no-margin {
      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    // @media (min-width: $lg) {
    //   padding-top: var(--space-desktop-8);
    //   padding-bottom: var(--space-desktop-8);
    // }

    .grid-item-video-mobile {
      grid-column: 1/-1;

      @media (min-width: $md) {
        display: none;
      }

      .video {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
          content: '';
          padding-top: 100%;
          width: 100%;
          display: block;
        }

        video {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .button {
        position: absolute;
        margin: auto;
        z-index: 1;
      }
    }

    .grid-item-video-desktop {
      display: none;
      grid-column: 1/-1;

      @media (min-width: $md) {
        display: block;
      }



      .video {
        position: relative;

        .screen-container{
          display: none;
        }


        @media (min-width: $md) {
          .screen-container {
            display: block;
            inset: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            .screen{
              border-radius: 20px;
              position: absolute;
              background-color: var(--color-red);
              transform: translate(10%, 15%);
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;

        @media (min-width: $md) {
          border-radius: 20px;
        }

        @media (min-width: $lg) {
          height: 600px;
        }
      }

      .button {
        position: absolute;
        z-index: 3;
        left: 0;

        @media (min-width: $md) {
          left: 40px;
          bottom: 40px;
        }
      }
    }
  }
}