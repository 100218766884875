.what-we-do {
  background-color: var(--color-white);
  overflow: hidden;
  .wrapper {
    padding-top: var(--space-mobile-2);
    padding-bottom: var(--space-mobile-3);
    padding-left: calc(var(--space-unit-1));
    padding-right: calc(var(--space-unit-1));

    @media (min-width: $lg) {
      padding-top: var(--space-desktop-7);
      padding-bottom: var(--space-desktop-8);
    }
  }

  .container {
    padding: 0;
    position: relative;
    @include container-grid(start);

    @media (max-width: 1500px) and (min-width: $md)  {
      max-width: 80%;
    }

    .top-right {
      display: none;
      grid-row: 1;
      justify-self: end;
      grid-column: 1/-1;

      @media (min-width: $lg) {
        display: block;
      }
    }

    .grid-item {
      grid-column: span 5;

      @media (min-width: $lg) {
        grid-column: span 5;
      }

      &.left-start {
        @media (min-width: $lg) {
          grid-row: 1;
          grid-column: 1/span 6;
        }
      }

      // &.grid-content {
      //   grid-column-start: 7;
      // }

      &.red-banner {
        grid-row: 2;

        @media (min-width: $lg) {
          grid-column: 1/span 6;
        }
      }

      &.grid-content {
        grid-row: 3;

        @media (min-width: $md) {
          grid-row: 2;
        }

        @media (min-width: $lg) {
          grid-column: 7/span 6;
        }
      }
    }

    .red-banner {
      background-color: var(--color-red);
      color: var(--color-white);
      padding: 25px 20px;
    }

  }
}