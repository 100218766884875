input:not([type="submit"], [type="button"]),
select,
textarea {
  display: block;
  width: 100%;
  background: none;
  border: none;
  font-weight: 700;
  color: var(--color-white);
  padding: 1rem 0 1.25rem;
  border-radius: 0;

  &:focus {
    outline: none;
  }
}

input::placeholder,
textarea::placeholder {
  color: var(--color-white);
  opacity: 1;
}

textarea {
  resize: vertical;
  max-height: 150px;
}

label {
  display: block;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--color-white);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-black) inset;
  transition: background-color .1s ease-in-out 0s;
}

select {
  appearance: none;
}