.accordion-heading {
  padding: 40px 0;
}

.accordion-line {
  height: 1px;
  width: 100%;
  background-color: var(--color-white);

  &:last-of-type {
    display: none;

    @media (min-width: $md) {
      display: block;
    }
  }
}

.accordion-control {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  &.focus-visible {
    outline-offset: 4px;
  }

  span {
    max-width: 85%;
    font-weight: normal;
  }

  svg {
    transition: opacity .3s ease;
  }

  svg path {
    transition: transform .3s ease;
    transform-origin: center;
  }

  &[aria-expanded="false"] {
    svg {
      opacity: .7;
    }

    svg path:nth-child(2) {
      transform: rotate(0deg);
      transform-origin: center;
    }
  }

  &[aria-expanded="true"] {
    svg path:nth-child(2) {
      transform: rotate(90deg);
    }
  }
}

.accordion-panel {
  //padding-bottom: 40px;
  display: block;

  p {
    padding-bottom: 40px;
  }

  // &[hidden] {
  //   visibility: hidden;
  // }
}